import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
const apiUrl = process.env.REACT_APP_API_URL;
const StableList = () => {
  const { t } = useTranslation();
  const [stables, setStables] = useState([]);
  const [deleteStableId, setDeleteStableId] = useState(null);
  const [editStableId, setEditStableId] = useState(null);
  const [stableName, setStableName] = useState('');
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  useEffect(() => {
    fetchStables();
  }, []);

  const fetchStables = () => {
    axios
      .get(`${apiUrl}/stables`)
      .then((response) => {
        setStables(response.data);
      })
      .catch((error) => console.error('Error fetching stables:', error));
  };

  const handleDelete = () => {
    axios
      .delete(`${apiUrl}/stables/${deleteStableId}`)
      .then(() => {
        setDeleteStableId(null);
        fetchStables();
      })
      .catch((error) => console.error('Error deleting stable:', error));
  };

  const handleEdit = () => {
    if (!window.confirm(t('edit_confirmation_message'))) {
      return;
    }

    axios
      .put(`${apiUrl}/stables/${editStableId}`, {
        stable_name: stableName,
      })
      .then(() => {
        setEditStableId(null);
        fetchStables();
      })
      .catch((error) => console.error('Error updating stable:', error));
  };

  const handleAdd = () => {
    axios
      .post(`${apiUrl}/stables`, {
        stable_name: stableName,
      })
      .then(() => {
        setIsAddDialogOpen(false);
        setStableName('');
        fetchStables();
      })
      .catch((error) => console.error('Error adding stable:', error));
  };

  const openEditDialog = (stable) => {
    setEditStableId(stable.stable_id);
    setStableName(stable.stable_name);
  };

  return (
    <Container style={{ direction: t('direction') }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        style={{ marginTop: 16, alignSelf: 'flex-start' }}
        onClick={() => setIsAddDialogOpen(true)}
      >
        {t('add_stable')}
      </Button>
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">{t('stable_name')}</TableCell>
              <TableCell align="center">{t('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stables.length > 0 ? (
              stables.map((stable, index) => (
                <TableRow key={stable.stable_id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{stable.stable_name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => openEditDialog(stable)} // فتح نافذة التعديل
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setDeleteStableId(stable.stable_id)} // تعيين ID الاسطبل للحذف
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t('no_stables_found')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={Boolean(deleteStableId)}
        onClose={() => setDeleteStableId(null)}
      >
        <DialogTitle>{t('delete_confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('delete_confirmation_message')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteStableId(null)} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleDelete} color="secondary">
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(editStableId)}
        onClose={() => setEditStableId(null)}
      >
        <DialogTitle>{t('edit_stable')}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t('stable_name')}
            value={stableName}
            onChange={(e) => setStableName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditStableId(null)} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleEdit} color="secondary">
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
        <DialogTitle>{t('add_stable')}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t('stable_name')}
            value={stableName}
            onChange={(e) => setStableName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddDialogOpen(false)} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleAdd} color="secondary">
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default StableList;
