import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Box,
  Alert,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_URL;

const MedicationsEdit = () => {
  const { t } = useTranslation(); // Translation support
  const { medicationId } = useParams();
  const navigate = useNavigate();
  const [medication, setMedication] = useState({
    medication_name: '',
    default_dosage: '',
    default_unit: '',
    price: 0, // Add the price field
  });
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    axios
      .get(`${apiUrl}/medications/${medicationId}`)
      .then((response) => setMedication(response.data))
      .catch((error) => {
        console.error('Error fetching medication data:', error);
        setError(t('fetch_medication_error'));
      });
  }, [medicationId, t]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`${apiUrl}/medications/${medicationId}`, medication)
      .then(() => {
        setSuccess(t('medication_update_success'));
        setError('');
        navigate('/medications'); // Redirect after successful update
      })
      .catch((error) => {
        console.error('Error updating medication:', error);
        setError(t('medication_update_error'));
        setSuccess('');
      });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5, textAlign: 'center' }}>
          {t('edit_medication')}
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}

        <Box
          component="form"
          sx={{ width: '100%', maxWidth: 600, mt: 2 }}
          onSubmit={handleSubmit}
        >
          <TextField
            label={t('medication_name')}
            value={medication.medication_name}
            onChange={(e) =>
              setMedication({ ...medication, medication_name: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('default_dosage')}
            value={medication.default_dosage}
            onChange={(e) =>
              setMedication({ ...medication, default_dosage: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('default_unit')}
            value={medication.default_unit}
            onChange={(e) =>
              setMedication({ ...medication, default_unit: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('price')}
            type="number"
            value={medication.price}
            onChange={(e) =>
              setMedication({ ...medication, price: e.target.value })
            }
            fullWidth
            margin="normal"
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 2 }}
            >
              {t('save')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/medications')}
            >
              {t('cancel')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MedicationsEdit;
