import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Alert, // تأكد من استيراد Alert من MUI
} from "@mui/material";
import { useTranslation } from "react-i18next";
const apiUrl = process.env.REACT_APP_API_URL;
const OwnerDetails = () => {
  const { t } = useTranslation();
  const { ownerId } = useParams(); // الحصول على ownerId من URL
  const [owner, setOwner] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchOwnerDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/owners/${ownerId}`);
        setOwner(response.data);
      } catch (err) {
        setError(t("error_loading_owner_details"));
      }
    };

    fetchOwnerDetails();
  }, [ownerId, t]);

  if (error) {
    return (
      <Container component="main" maxWidth="sm">
        <Box sx={{ mt: 8 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  if (!owner) {
    return (
      <Container component="main" maxWidth="sm">
        <Box sx={{ mt: 8 }}>
          <Typography variant="h6">{t("loading")}</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box sx={{ mt: 8 }}>
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Typography
            variant="h5"
            gutterBottom
            align="center" // توسيط النص
          >
            {`${owner.first_name} ${owner.last_name}`}
          </Typography>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th">{t("phone")}</TableCell>
                  <TableCell>{owner.phone || t("no_data")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("email")}</TableCell>
                  <TableCell>{owner.email || t("no_data")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">{t("address")}</TableCell>
                  <TableCell>{owner.address || t("no_data")}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Container>
  );
};

export default OwnerDetails;
