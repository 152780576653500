import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Avatar,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const apiUrl = process.env.REACT_APP_API_URL;

const OwnerList = () => {
  const { t, i18n } = useTranslation();
  const [owners, setOwners] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteOwnerId, setDeleteOwnerId] = useState(null);
  const [confirmationText, setConfirmationText] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOwners = async () => {
      try {
        const token = localStorage.getItem('token');

        const ownersResponse = await axios.get(`${apiUrl}/owners`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setOwners(ownersResponse.data);
      } catch (err) {
        console.error('Failed to fetch owners: ' + err.message);
        setError(t('error_fetching_owners'));
      }
    };

    fetchOwners();
  }, [t]);

  const handleViewClick = (ownerId) => {
    navigate(`/owner-details/${ownerId}`);
  };

  const handleEditClick = (ownerId) => {
    navigate(`/owner-edit/${ownerId}`);
  };

  const handleDeleteClick = (ownerId) => {
    setDeleteOwnerId(ownerId);
    setDeleteConfirmationOpen(true);
  };

  const confirmDelete = async () => {
    if (confirmationText.toLowerCase() !== 'delete') {
      setError(t('please_type_delete_to_confirm'));
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${apiUrl}/owners/${deleteOwnerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOwners(owners.filter((owner) => owner.owner_id !== deleteOwnerId));
      setSuccess(t('owner_deleted_successfully'));
      setDeleteConfirmationOpen(false);
      setConfirmationText('');
    } catch (err) {
      console.error('Failed to delete owner: ' + err.message);
      setError(t('error_deleting_owner'));
    }
  };

  const handleAddOwner = () => {
    navigate('/register-owner');
  };

  const handleCloseDialog = () => {
    setDeleteConfirmationOpen(false);
    setConfirmationText('');
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
    >
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          {t('owner_details')}
        </Typography>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start', // محاذاة العناصر لليسار حسب التنسيق
            alignItems: 'center', // التأكد من أن الزر والنص في نفس الخط
            mb: 1,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddOwner}
            startIcon={<AddIcon />}
            style={{ marginTop: 0, alignSelf: 'center' }} // تعديل المحاذاة
          >
            {t('add_owner')}
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ marginTop: 2.5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">{t('owner_name')}</TableCell>
                <TableCell align="center">{t('phone')}</TableCell>
                <TableCell align="center">{t('email')}</TableCell>
                <TableCell align="center">{t('address')}</TableCell>
                <TableCell align="center">{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {owners.map((owner, index) => (
                <TableRow key={owner.owner_id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: i18n.language === 'ar' ? 2 : 1,
                      }}
                    >
                      <Avatar sx={{ mr: 2 }}>
                        {owner.first_name ? owner.first_name.charAt(0) : '?'}
                      </Avatar>
                      <Box>
                        <Typography variant="body2">
                          {`${owner.first_name || ''} ${owner.last_name || ''}`}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">{owner.phone || '-'}</TableCell>
                  <TableCell align="center">{owner.email || '-'}</TableCell>
                  <TableCell align="center">{owner.address || '-'}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => handleViewClick(owner.owner_id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleEditClick(owner.owner_id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteClick(owner.owner_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleCloseDialog}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="delete-dialog-title">
            {t('confirm_deletion')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('please_type_delete_to_confirm')}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="confirmationText"
              label={t('type_delete')}
              fullWidth
              variant="standard"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={confirmDelete} color="error">
              {t('delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default OwnerList;
