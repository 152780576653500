import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const ProceduresList = () => {
  const { t } = useTranslation();
  const [procedures, setProcedures] = useState([]);
  const navigate = useNavigate();

  // تعريف الدالة `fetchProcedures` قبل استدعائها
  const fetchProcedures = () => {
    axios
      .get(`${apiUrl}/procedures`)
      .then((response) => setProcedures(response.data))
      .catch((error) => console.error('Error fetching procedures:', error));
  };

  // استدعاء الدالة `fetchProcedures` داخل `useEffect`
  useEffect(() => {
    fetchProcedures();
  }, []);

  /*const handleDelete = (id) => {
    if (window.confirm(t('proceduresList.confirmDelete'))) {
      axios
        .delete(`${apiUrl}/procedures/${id}`)
        .then(() => fetchProcedures())
        .catch((error) => console.error('Error deleting procedure:', error));
    }
  };*/
  

  const handleDelete = async (id) => {
    if (window.confirm(t('proceduresList.confirmDelete'))) {
      try {
        await axios.delete(`${apiUrl}/procedures/${id}`);
        fetchProcedures();
      } catch (error) {
        console.error('Error deleting procedure:', error);
      }
    }
  };
  

  return (
    <Container>
      <h1 style={{ textAlign: 'center', marginTop: 20 }}>
        {t('proceduresList.title')}
      </h1>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => navigate('/procedure-add')}
        style={{ marginBottom: 16 }}
      >
        {t('proceduresList.addNew')}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">{t('proceduresList.name')}</TableCell>
              <TableCell align="center">
                {t('proceduresList.description')}
              </TableCell>
              <TableCell align="center">{t('proceduresList.price')}</TableCell>
              <TableCell align="center">
                {t('proceduresList.actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {procedures.length > 0 ? (
              procedures.map((procedure, index) => (
                <TableRow key={procedure.procedure_id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {procedure.procedure_name}
                  </TableCell>
                  <TableCell align="center">{procedure.description}</TableCell>
                  <TableCell align="center">{procedure.price}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(`/ProcedureEdit/${procedure.procedure_id}`)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(procedure.procedure_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {t('proceduresList.noProcedures')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ProceduresList;
