import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Box,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const apiUrl = process.env.REACT_APP_API_URL;

const AccommodationList = () => {
  const { t } = useTranslation();
  const [accommodations, setAccommodations] = useState([]);
  const [horses, setHorses] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [editAccommodationId, setEditAccommodationId] = useState(null);

  const [startDate, setStartDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [endDate, setEndDate] = useState('');
  const [horseId, setHorseId] = useState('');
  const [roomId, setRoomId] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchAccommodations();
    fetchHorses();
    fetchRooms();
  }, []);

  const fetchAccommodations = () => {
    axios
      .get(`${apiUrl}/accommodations`)
      .then((response) => setAccommodations(response.data))
      .catch((error) => console.error('Error fetching accommodations:', error));
  };

  const fetchHorses = () => {
    axios
      .get(`${apiUrl}/horses`)
      .then((response) => setHorses(response.data))
      .catch((error) => console.error('Error fetching horses:', error));
  };

  const fetchRooms = () => {
    axios
      .get(`${apiUrl}/rooms-with-stables`)
      .then((response) => {
        const roomsWithStableName = response.data.map((room) => ({
          ...room,
          displayName: room.room_name
            ? `${room.stable_name} - ${room.room_name}`
            : 'Room Name Not Available',
        }));
        setRooms(roomsWithStableName);
      })
      .catch((error) => console.error('Error fetching rooms:', error));
  };

  const checkForExistingRoom = () => {
    return accommodations.some(
      (acc) =>
        acc.horse_id === horseId && acc.accommodation_id !== editAccommodationId
    );
  };

  const checkRoomConditions = async () => {
    try {
      const response = await axios.get(`${apiUrl}/accommodations`);
      const roomAccommodations = response.data.filter(
        (acc) => acc.room_id === roomId
      );

      if (roomAccommodations.length === 0) return true; // No horses in the room, condition passed

      const currentHorse = horses.find((horse) => horse.horse_id === horseId);
      const currentHorseAgeMonths = getHorseAgeInMonths(
        currentHorse.date_of_birth
      );

      const conditionMet = roomAccommodations.every((acc) => {
        const otherHorse = horses.find(
          (horse) => horse.horse_id === acc.horse_id
        );
        const otherHorseAgeMonths = getHorseAgeInMonths(
          otherHorse.date_of_birth
        );

        return (
          (currentHorseAgeMonths < 5 && otherHorse.gender === 'female') ||
          (otherHorseAgeMonths < 5 && currentHorse.gender === 'female')
        );
      });

      return conditionMet;
    } catch (error) {
      console.error('Error checking room conditions:', error);
      return false;
    }
  };

  const getHorseAgeInMonths = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    const ageInMonths =
      (currentDate.getFullYear() - birthDate.getFullYear()) * 12 +
      currentDate.getMonth() -
      birthDate.getMonth();
    return ageInMonths;
  };

  const handleAddOrEdit = async () => {
    if (checkForExistingRoom()) {
      setErrorMessage(t('accommodationList.conflictExistingRoom'));
      setErrorOpen(true);
      return;
    }

    const roomConditionsMet = await checkRoomConditions();

    if (!roomConditionsMet) {
      setErrorMessage(t('accommodationList.conflictRoomConditions'));
      setErrorOpen(true);
      return;
    }

    const formattedStartDate = startDate
      ? new Date(startDate).toISOString().split('T')[0]
      : null;
    const formattedEndDate = endDate
      ? new Date(endDate).toISOString().split('T')[0]
      : null;

    const data = {
      horse_id: horseId,
      room_id: roomId,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    };

    if (editAccommodationId) {
      axios
        .put(`${apiUrl}/accommodations/${editAccommodationId}`, data)
        .then(() => {
          fetchAccommodations();
          resetForm();
        })
        .catch((error) =>
          console.error('Error updating accommodation:', error)
        );
    } else {
      axios
        .post(`${apiUrl}/accommodations`, data)
        .then(() => {
          fetchAccommodations();
          resetForm();
        })
        .catch((error) => console.error('Error adding accommodation:', error));
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(`${apiUrl}/accommodations/${id}`)
      .then(() => fetchAccommodations())
      .catch((error) => console.error('Error deleting accommodation:', error));
  };

  const openEditDialog = (accommodation) => {
    setEditAccommodationId(accommodation.accommodation_id);
    setHorseId(accommodation.horse_id);
    setRoomId(accommodation.room_id);
    setStartDate(
      accommodation.start_date
        ? new Date(accommodation.start_date).toISOString().split('T')[0]
        : ''
    );
    setEndDate(
      accommodation.end_date
        ? new Date(accommodation.end_date).toISOString().split('T')[0]
        : ''
    );
    setIsDialogOpen(true);
  };

  const resetForm = () => {
    setEditAccommodationId(null);
    setHorseId('');
    setRoomId('');
    setStartDate(new Date().toISOString().split('T')[0]);
    setEndDate('');
    setIsDialogOpen(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
    setErrorMessage('');
  };

  return (
    <Container>
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5, textAlign: 'center' }}>
          {t('accommodationList.title')}
        </Typography>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start', // محاذاة العناصر لليسار
            alignItems: 'center', // التأكد من أن الزر والنص في نفس الخط
            mb: 2.5,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setIsDialogOpen(true)}
            style={{ marginTop: 0, alignSelf: 'center' }} // تعديل المحاذاة
          >
            {t('accommodationList.addNew')}
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper} style={{ marginTop: 2.5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t('accommodationList.horseName')}
              </TableCell>
              <TableCell align="center">
                {t('accommodationList.stableRoomName')}
              </TableCell>
              <TableCell align="center">
                {t('accommodationList.capacity')}
              </TableCell>
              <TableCell align="center">
                {t('accommodationList.startDate')}
              </TableCell>
              <TableCell align="center">
                {t('accommodationList.endDate')}
              </TableCell>
              <TableCell align="center">
                {t('accommodationList.actions')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {accommodations.length > 0 ? (
              accommodations.map((accommodation) => (
                <TableRow key={accommodation.accommodation_id}>
                  <TableCell align="center">
                    {accommodation.horse_name}
                  </TableCell>
                  <TableCell align="center">
                    {accommodation.stable_name && accommodation.room_name
                      ? `${accommodation.stable_name} - ${accommodation.room_name}`
                      : 'Room Name Not Available'}
                  </TableCell>
                  <TableCell align="center">{accommodation.capacity}</TableCell>
                  <TableCell align="center">
                    {new Date(accommodation.start_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {accommodation.end_date
                      ? new Date(accommodation.end_date).toLocaleDateString()
                      : 'N/A'}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => openEditDialog(accommodation)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        handleDelete(accommodation.accommodation_id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  {t('accommodationList.noAccommodations')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Adding/Editing Accommodation */}
      <Dialog open={isDialogOpen} onClose={resetForm} fullWidth maxWidth="sm">
        <DialogTitle>
          {editAccommodationId
            ? t('accommodationList.editAccommodation')
            : t('accommodationList.addAccommodation')}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <InputLabel>{t('accommodationList.horseName')}</InputLabel>
            <Select
              value={horseId}
              onChange={(e) => setHorseId(e.target.value)}
            >
              {horses.map((horse) => (
                <MenuItem key={horse.horse_id} value={horse.horse_id}>
                  {horse.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <InputLabel>{t('accommodationList.stableRoomName')}</InputLabel>
            <Select value={roomId} onChange={(e) => setRoomId(e.target.value)}>
              {rooms.map((room) => (
                <MenuItem key={room.room_id} value={room.room_id}>
                  {room.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label={t('accommodationList.startDate')}
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginBottom: 16 }}
          />
          <TextField
            fullWidth
            label={t('accommodationList.endDate')}
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            {t('accommodationList.cancel')}
          </Button>
          <Button onClick={handleAddOrEdit} color="secondary">
            {editAccommodationId
              ? t('accommodationList.update')
              : t('accommodationList.save')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for error messages */}
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AccommodationList;
