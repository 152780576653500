import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ isAuthenticated }) => {
  const token = localStorage.getItem("token");

  // تحقق من وجود توكن في localStorage
  const isTokenValid = token !== null;

  return isAuthenticated && isTokenValid ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
