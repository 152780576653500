import React from "react";

const StableRoomDetails = () => {
  return (
    <div>
      <h1>Stable Room Details</h1>
      {/* Your code for displaying detailed information about a specific room */}
    </div>
  );
};

export default StableRoomDetails;
