import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  TextField,
  Container,
  Box,
  Alert,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const SystemSettings = () => {
  const { t } = useTranslation();
  const [systemName, setSystemName] = useState('');
  const [newSystemName, setNewSystemName] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSystemName = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/system-name`
        );
        setSystemName(response.data.system_name);
      } catch (error) {
        setError(t('fetch_system_name_error'));
      }
    };

    fetchSystemName();
  }, [t]);

  const handleUpdate = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/system-name`, {
        system_name: newSystemName,
      });
      setSuccess(t('system_name_update_success'));
      setError('');
      setSystemName(newSystemName);
      document.title = newSystemName; // تحديث عنوان المتصفح
    } catch (error) {
      setError(t('system_name_update_error'));
      setSuccess('');
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5, textAlign: 'center' }}>
          {t('system_settings')}
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}

        <Box component="form" sx={{ width: '100%', maxWidth: 600, mt: 2 }}>
          <TextField
            label={t('current_system_name')}
            value={systemName}
            disabled
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('new_system_name')}
            value={newSystemName}
            onChange={(e) => setNewSystemName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              sx={{ marginRight: 2 }}
            >
              {t('update_system_name')}
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => window.history.back()} // إعادة المستخدم للصفحة السابقة
            >
              {t('cancel')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SystemSettings;
