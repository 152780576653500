import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily:
      '"Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: "center", // Center text in all table cells
        },
      },
    },
  },
});

export default theme;

