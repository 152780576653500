import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const apiUrl = process.env.REACT_APP_API_URL;

const StableRoomList = () => {
  const [rooms, setRooms] = useState([]);
  const [stables, setStables] = useState([]);
  const [editRoomId, setEditRoomId] = useState(null);
  const [roomName, setRoomName] = useState("");
  const [stableId, setStableId] = useState("");
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  const fetchRooms = () => {
    axios
      .get(`${apiUrl}/rooms`)
      .then((response) => {
        setRooms(response.data);
      })
      .catch((error) => console.error("Error fetching stable rooms:", error));
  };

  const fetchStables = useCallback(() => {
    axios
      .get(`${apiUrl}/stables`)
      .then((response) => {
        setStables(response.data);
        if (response.data.length > 0 && !stableId) {
          setStableId(response.data[0].stable_id);
        }
      })
      .catch((error) => console.error("Error fetching stables:", error));
  }, [stableId]);

  useEffect(() => {
    fetchRooms();
    fetchStables();
  }, [fetchStables]);

  const handleAdd = () => {
    axios
      .post(`${apiUrl}/stable-rooms`, {
        room_name: roomName,
        stable_id: stableId,
      })
      .then(() => {
        setIsAddDialogOpen(false);
        setRoomName("");
        setStableId(stables.length > 0 ? stables[0].stable_id : "");
        fetchRooms();
        setError(null);
      })
      .catch((error) => {
        setError(error.response?.data?.error || "An error occurred");
      });
  };

  const handleEdit = () => {
    axios
      .put(`${apiUrl}/stable-rooms/${editRoomId}`, {
        room_name: roomName,
        stable_id: stableId,
      })
      .then(() => {
        setEditRoomId(null);
        setRoomName("");
        setStableId(stables.length > 0 ? stables[0].stable_id : "");
        fetchRooms();
        setError(null);
      })
      .catch((error) => {
        setError(error.response?.data?.error || "An error occurred");
      });
  };

  const handleDelete = (roomId) => {
    if (!window.confirm("Are you sure you want to delete this room?")) {
      return;
    }

    axios
      .delete(`${apiUrl}/stable-rooms/${roomId}`)
      .then(() => {
        fetchRooms(); // تحديث قائمة الغرف بعد الحذف
        setError(null);
      })
      .catch((error) => {
        setError(error.response?.data?.error || "An error occurred");
      });
  };

  const openEditDialog = (room) => {
    setEditRoomId(room.room_id);
    setRoomName(room.room_name);
    setStableId(room.stable_id);
  };

  return (
    <Container>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        style={{ marginTop: 16, alignSelf: "flex-start" }}
        onClick={() => setIsAddDialogOpen(true)}
      >
        Add New Room
      </Button>
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Room Name</TableCell>
              <TableCell align="center">Stable Name</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.length > 0 ? (
              rooms.map((room) => (
                <TableRow key={room.room_id}>
                  <TableCell align="center">{room.room_name}</TableCell>
                  <TableCell align="center">{room.stable_name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => openEditDialog(room)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(room.room_id)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No Rooms Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {error && (
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={6000}
          onClose={() => setError(null)}
        >
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}

      <Dialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Add New Room</DialogTitle>
        <DialogContent>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <InputLabel>Stable Name</InputLabel>
            <Select
              value={stableId || ""}
              onChange={(e) => setStableId(e.target.value)}
            >
              {stables.map((stable) => (
                <MenuItem key={stable.stable_id} value={stable.stable_id}>
                  {stable.stable_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Room Name"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={Boolean(editRoomId)}
        onClose={() => setEditRoomId(null)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit Room</DialogTitle>
        <DialogContent>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <InputLabel>Stable Name</InputLabel>
            <Select
              value={stableId || ""}
              onChange={(e) => setStableId(e.target.value)}
            >
              {stables.map((stable) => (
                <MenuItem key={stable.stable_id} value={stable.stable_id}>
                  {stable.stable_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Room Name"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditRoomId(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEdit} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default StableRoomList;
