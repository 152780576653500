import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

const StableDetails = () => {
  const { stable_id } = useParams();
  const [stable, setStable] = useState(null);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/stables/${stable_id}`)
      .then((response) => setStable(response.data))
      .catch((error) => console.error("Error fetching stable:", error));

    axios
      .get(`/api/stables/${stable_id}/rooms`)
      .then((response) => setRooms(response.data))
      .catch((error) => console.error("Error fetching rooms:", error));
  }, [stable_id]);

  const addRoom = () => {
    // Implement room addition logic
  };

  return (
    <Container>
      <h2>{stable?.stable_name}</h2>
      <Button onClick={addRoom}>Add Room</Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Room Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.map((room) => (
              <TableRow key={room.room_id}>
                <TableCell>{room.room_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default StableDetails;
