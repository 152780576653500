import React from "react";
import { createRoot } from "react-dom/client"; 
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

// Initialize the root using createRoot from react-dom/client
const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// This function is optional, and can be used for measuring performance in your app.
reportWebVitals();
