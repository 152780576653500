import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  IconButton,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';

const apiUrl = process.env.REACT_APP_API_URL;

const UserList = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Moved inside the component
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false); // حالة لفتح نافذة التأكيد
  const [confirmationText, setConfirmationText] = useState(''); // لتخزين نص التأكيد
  const [deleteUserId, setDeleteUserId] = useState(null); // لتحديد المستخدم المراد حذفه
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users`);
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch users:', error);
        setError(t('error_fetching_users'));
      }
    };

    fetchUsers();
  }, [t]);

  const handleViewDetails = (userId) => {
    navigate(`/user-details/${userId}`);
  };

  const handleEditUser = (userId) => {
    navigate(`/useredit/${userId}`);
  };

  // فتح نافذة تأكيد الحذف
  const handleOpenDeleteConfirmation = (userId) => {
    setDeleteUserId(userId); // تحديد المستخدم المراد حذفه
    setDeleteConfirmationOpen(true); // فتح نافذة التأكيد
  };

  // إغلاق نافذة التأكيد
  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
    setConfirmationText(''); // إعادة تعيين نص التأكيد
  };

  const confirmDelete = async () => {
    if (confirmationText.toLowerCase() !== 'delete') {
      setError(t('please_type_delete_to_confirm'));
      return;
    }

    try {
      await axios.delete(`${apiUrl}/users/${deleteUserId}`);
      setUsers(users.filter((user) => user.user_id !== deleteUserId));
      handleCloseDeleteConfirmation(); // إغلاق النافذة بعد نجاح الحذف
    } catch (error) {
      console.error('Failed to delete user:', error);
      setError(t('error_deleting_user'));
    }
  };

  const handleDeleteUser = (userId) => {
    handleOpenDeleteConfirmation(userId); // فتح نافذة التأكيد
  };

  const handleOpenChangePassword = (userId) => {
    setSelectedUserId(userId);
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
    setSelectedUserId(null);
    setNewPassword('');
  };

  const handleChangePassword = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage

      // Call the updated API route
      await axios.put(
        `${apiUrl}/changepasswordbyadmin/${selectedUserId}/password`, // userId is sent as a URL parameter
        { password: newPassword }, // Send new password in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include JWT token in the request headers for authentication
          },
        }
      );
      handleCloseChangePassword(); // Close the change password dialog/modal after success
    } catch (error) {
      console.error('Failed to change password:', error);
      setError('Failed to change password. Please try again.'); // Handle error appropriately
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          {t('user_list')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start', // محاذاة العناصر لليسار
            alignItems: 'center', // التأكد من أن الزر والنص في نفس الخط
            mb: 1,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/userregistration')}
            style={{ marginTop: 0, alignSelf: 'center' }} // تعديل المحاذاة
          >
            {t('add_user')}
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ marginTop: 2.5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">{t('username')}</TableCell>
                <TableCell align="center">{t('first_name')}</TableCell>
                <TableCell align="center">{t('last_name')}</TableCell>
                <TableCell align="center">{t('email')}</TableCell>
                <TableCell align="center">{t('phone')}</TableCell>
                <TableCell align="center">{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={user.user_id}>
                  <TableCell align="center">
                    <Typography variant="body2">{index + 1}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{user.username}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{user.first_name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{user.last_name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{user.email}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2">{user.phone}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => handleViewDetails(user.user_id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleEditUser(user.user_id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteUser(user.user_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenChangePassword(user.user_id)}
                    >
                      <LockResetIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Dialog for changing password */}
      <Dialog open={openChangePassword} onClose={handleCloseChangePassword}>
        <DialogTitle>{t('change_password')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('new_password')}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChangePassword} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleChangePassword} color="primary">
            {t('submit')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for confirming delete */}
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleCloseDeleteConfirmation}
      >
        <DialogTitle>{t('confirm_deletion')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('please_type_delete_to_confirm')}
            fullWidth
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={confirmDelete} color="error">
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserList;
