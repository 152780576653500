import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import '../../App.css'; // Ensure your styles are set up properly

const apiUrl = process.env.REACT_APP_API_URL;

const HorseList = () => {
  const { t, i18n } = useTranslation();
  const [horses, setHorses] = useState([]);
  const [owners, setOwners] = useState({});
  const [error, setError] = useState('');
  const [genders, setGenders] = useState({
    S: t('stallion'),
    M: t('mare'),
  });
  const [colors, setColors] = useState({});
  const [breeds, setBreeds] = useState({});
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteHorseId, setDeleteHorseId] = useState(null);
  const [confirmationText, setConfirmationText] = useState('');

  const navigate = useNavigate(); // استخدم useNavigate

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // الحصول على رمز JWT من التخزين المحلي

        // جلب بيانات الخيول
        const horsesResponse = await axios.get(`${apiUrl}/horseslist`, {
          headers: {
            Authorization: `Bearer ${token}`, // إرسال الرمز مع الطلب
          },
        });
        setHorses(horsesResponse.data);

        // جلب بيانات الملاك
        const ownersResponse = await axios.get(`${apiUrl}/owners/summary`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const ownersMap = {};
        ownersResponse.data.forEach((owner) => {
          ownersMap[owner.owner_id] = owner.name; // ربط `owner_id` مع `name`
        });
        setOwners(ownersMap);

        const colorsResponse = await axios.get(`${apiUrl}/horse_colors`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const colorsMap = {};
        colorsResponse.data.forEach((color) => {
          colorsMap[color.color_id] =
            i18n.language === 'ar' ? color.color_arabic : color.color_english;
        });
        setColors(colorsMap);

        const breedsResponse = await axios.get(`${apiUrl}/horse_breeds`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const breedsMap = {};
        breedsResponse.data.forEach((breed) => {
          breedsMap[breed.breed_id] =
            i18n.language === 'ar' ? breed.breed_arabic : breed.breed_english;
        });
        setBreeds(breedsMap);
      } catch (err) {
        console.error('Failed to fetch data: ' + err.message);
        setError(t('error_fetching_horses'));
      }
    };

    fetchData();
  }, [t, i18n.language]);

  useEffect(() => {
    setGenders({
      S: i18n.language === 'ar' ? 'حصان' : 'Stallion',
      M: i18n.language === 'ar' ? 'فرس' : 'Mare',
    });
  }, [i18n.language]);

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}/${month}/${day}`;
  };
  const handleDeleteClick = (horseId) => {
    setDeleteHorseId(horseId);
    setDeleteConfirmationOpen(true);
  };
  const confirmDelete = async () => {
    if (confirmationText.toLowerCase() !== 'delete') {
      setError(t('please_type_delete_to_confirm'));
      return;
    }

    try {
      const token = localStorage.getItem('token'); // الحصول على التوكن من التخزين المحلي

      // إرسال الطلب لحذف الحصان
      await axios.delete(`${apiUrl}/horses/${deleteHorseId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // إرسال التوكن مع الطلب
        },
      });

      // تحديث قائمة الخيول بعد الحذف
      setHorses(horses.filter((horse) => horse.horse_id !== deleteHorseId));
      setDeleteConfirmationOpen(false);
      setConfirmationText('');
    } catch (err) {
      console.error('Failed to delete horse: ', err); // طباعة الخطأ في وحدة التحكم
      setError(t('error_deleting_horse'));
    }
  };
  const handleCloseDialog = () => {
    setDeleteConfirmationOpen(false);
    setConfirmationText('');
  };
  // Function to extract name and code
  const formatHorseName = (horseName) => {
    const regex = /(.*)\(([^)]+)\)$/; // Regular expression to match text with parentheses
    const match = horseName.match(regex);

    if (match) {
      return {
        name: match[1].trim(), // الاسم بدون الأقواس
        code: match[2].trim(), // الرمز داخل الأقواس
      };
    }

    // Return the full name if no match is found
    return { name: horseName, code: '' };
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          {t('horse_details')}
        </Typography>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start', // محاذاة العناصر لليسار
            alignItems: 'center', // التأكد من أن الزر والنص في نفس الخط
            mb: 1,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/register-horse')}
            style={{ marginTop: 0, alignSelf: 'center' }} // تعديل المحاذاة
          >
            {t('register_horse')}
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ marginTop: 2.5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t('horse_name')}</TableCell>
                <TableCell>{t('gender')}</TableCell>
                <TableCell>{t('date_of_birth')}</TableCell>
                <TableCell>{t('owner_name')}</TableCell>
                <TableCell>{t('breed')}</TableCell>
                <TableCell>{t('color_id')}</TableCell>
                <TableCell>{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {horses.map((horse, index) => (
                <TableRow key={horse.horse_id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: i18n.language === 'ar' ? 2 : 1,
                        minWidth: '200px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: i18n.language === 'ar' ? 2 : 1,
                          minWidth: '200px',
                        }}
                      >
                        <Avatar sx={{ mr: 2 }}>{horse.name.charAt(0)}</Avatar>
                        <Box
                          sx={{
                            flexGrow: 1,
                            textAlign:
                              i18n.language === 'ar' ? 'right' : 'left', // Ensure text alignment for both name and RFID chip number
                          }}
                        >
                          <Typography variant="body2">
                            {i18n.language === 'ar'
                              ? horse.name_arabic
                              : (() => {
                                  const { name, code } = formatHorseName(
                                    horse.name || '-'
                                  );
                                  return (
                                    <>
                                      {name} {code && <span>({code})</span>}
                                    </>
                                  );
                                })()}
                          </Typography>
                          
                          <Typography variant="caption" color="textSecondary">
                            {horse.rfid_chip_number || '-'}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{genders[horse.gender] || '-'}</TableCell>
                  <TableCell>{formatDate(horse.date_of_birth)}</TableCell>
                  <TableCell>{owners[horse.owner_id] || '-'}</TableCell>
                  <TableCell>{breeds[horse.breed_id] || '-'}</TableCell>
                  <TableCell>{colors[horse.color_id] || '-'}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(`/horse-details/${horse.horse_id}`)
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => navigate(`/horse-edit/${horse.horse_id}`)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteClick(horse.horse_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleCloseDialog}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="delete-dialog-title">
            {t('confirm_deletion')}
          </DialogTitle>
          <DialogContent>
            <Typography>{t('please_type_delete_to_confirm')}</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="confirmationText"
              label={t('type_delete')}
              fullWidth
              variant="standard"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={confirmDelete} color="error">
              {t('delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default HorseList;
