import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom'; // استخدام useParams للحصول على ownerId

const apiUrl = process.env.REACT_APP_API_URL;

const OwnerEdit = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { ownerId } = useParams(); // الحصول على ownerId من عنوان URL
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [userId, setUserId] = useState(''); // حالة لحفظ المستخدم المختار
  const [users, setUsers] = useState([]); // حالة لحفظ المستخدمين
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (ownerId) {
      const fetchOwnerData = async () => {
        try {
          const response = await axios.get(`${apiUrl}/owners/${ownerId}`);
          const ownerData = response.data;

          setFirstName(ownerData.first_name);
          setLastName(ownerData.last_name);
          setPhone(ownerData.phone);
          setEmail(ownerData.email);
          setAddress(ownerData.address);
          setUserId(ownerData.user_id); // تعيين user_id
        } catch (error) {
          setError(t('error_loading_owner_data'));
        }
      };

      fetchOwnerData();
    } else {
      setError('Owner ID is not defined.');
    }

    // استرجاع المستخدمين
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users`);
        setUsers(response.data);
      } catch (error) {
        setError(t('error_loading_users'));
      }
    };

    fetchUsers();
  }, [ownerId, t]);

  const validateFields = () => {
    if (!firstName) {
      setError(t('please_fill_first_name'));
      return false;
    }
    if (!lastName) {
      setError(t('please_fill_last_name'));
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    try {
      await axios.put(`${apiUrl}/owners/${ownerId}`, {
        first_name: firstName,
        last_name: lastName,
        phone,
        email,
        address,
        user_id: userId, // إضافة user_id
      });
      setSuccess(t('owner_updated_successfully'));
      setError('');
      navigate('/owner-list');
    } catch (err) {
      setError(t('error_updating_owner'));
      setSuccess('');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
      >
        <Typography component="h1" variant="h5">
          {t('edit_owner')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label={t('first_name')}
            name="firstName"
            autoComplete="firstName"
            autoFocus
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label={t('last_name')}
            name="lastName"
            autoComplete="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="phone"
            label={t('phone')}
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label={t('email')}
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="address"
            label={t('address')}
            name="address"
            multiline
            rows={4}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          {/* إضافة القائمة المنسدلة للمستخدمين */}
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('assigned_user')}</InputLabel>
            <Select
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              label={t('assigned_user')}
            >
              {users.map((user) => (
                <MenuItem key={user.user_id} value={user.user_id}>
                  {user.first_name} {user.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 3,
              mb: 2,
            }}
          >
            <Button type="submit" variant="contained" sx={{ width: '48%' }}>
              {t('edit_owner')}
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/owner-list')} // استبدل '/owner-list' بالمسار المناسب لزر الإلغاء
              sx={{ width: '48%' }}
            >
              {t('cancel')}
            </Button>
          </Box>

          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>
      </Box>
    </Container>
  );
};

export default OwnerEdit;
