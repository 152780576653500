import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const RecordList = () => {
  const { t, i18n } = useTranslation();
  const [records, setRecords] = useState([]);
  const [error, setError] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(null);
  const [confirmationText, setConfirmationText] = useState('');
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}/${month}/${day}`;
  };
  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${apiUrl}/vetclinic-records`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRecords(response.data);
      } catch (err) {
        console.error('Failed to fetch records:', err.message);
        setError(t('error_fetching_records'));
      }
    };

    fetchRecords();
  }, [t, i18n.language]);

  const handleDeleteClick = (recordId) => {
    setDeleteRecordId(recordId);
    setDeleteConfirmationOpen(true);
  };

  const confirmDelete = async () => {
    if (confirmationText.toLowerCase() !== 'delete') {
      setError(t('please_type_delete_to_confirm'));
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${apiUrl}/vetclinic-records/${deleteRecordId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setRecords(
        records.filter((record) => record.record_id !== deleteRecordId)
      );
      setDeleteConfirmationOpen(false);
      setConfirmationText('');
    } catch (err) {
      console.error('Failed to delete record:', err.message);
      setError(t('error_deleting_record'));
    }
  };

  const handleCloseDialog = () => {
    setDeleteConfirmationOpen(false);
    setConfirmationText('');
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          {t('vetclinic_records')}
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: 2.5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t('record_date')}</TableCell>
                <TableCell>{t('horse_name')}</TableCell>
                <TableCell>{t('total_amount')}</TableCell>
                <TableCell>{t('payment_type')}</TableCell>
                <TableCell>{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((record, index) => (
                <TableRow key={record.record_id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{formatDate(record.record_date)}</TableCell>
                  <TableCell>{record.horse_name}</TableCell>
                  <TableCell>{record.total_amount}</TableCell>
                  <TableCell>{record.payment_type_name}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(`/record-details/${record.record_id}`)
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        navigate(`/record-edit/${record.record_id}`)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteClick(record.record_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleCloseDialog}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="delete-dialog-title">
            {t('confirm_deletion')}
          </DialogTitle>
          <DialogContent>
            <Typography>{t('please_type_delete_to_confirm')}</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="confirmationText"
              label={t('type_delete')}
              fullWidth
              variant="standard"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={confirmDelete} color="error">
              {t('delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default RecordList;
