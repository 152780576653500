import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box, // استيراد Box من MUI
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility'; // أيقونة العرض

const apiUrl = process.env.REACT_APP_API_URL;

const ClientsList = () => {
  const [clients, setClients] = useState([]);
  const [open, setOpen] = useState(false);
  const [clientData, setClientData] = useState({
    client_name: '',
    email: '',
    mobile: '',
    address: '',
    notes: '',
  });

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${apiUrl}/vetclinic-clients`);
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData({ ...clientData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (clientData.client_id) {
        await axios.put(
          `${apiUrl}/vetclinic-clients/${clientData.client_id}`,
          clientData
        );
      } else {
        await axios.post(`${apiUrl}/vetclinic-clients`, clientData);
      }
      fetchClients();
      handleClose();
    } catch (error) {
      console.error('Error saving client:', error);
    }
  };

  const handleEdit = (client) => {
    setClientData(client);
    handleOpen();
  };

  const handleDelete = async (client_id) => {
    try {
      await axios.delete(`${apiUrl}/vetclinic-clients/${client_id}`);
      fetchClients();
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const handleView = (client) => {
    // يمكن أن يحتوي هذا على المنطق الخاص بعرض تفاصيل العميل
    console.log('Viewing client:', client);
  };

  return (
    <div>
      <Box display="flex" justifyContent="center" mt={3} mb={2}>
        <h1>Clients Management</h1>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add Client
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map((client) => (
            <TableRow key={client.client_id}>
              <TableCell>{client.client_name}</TableCell>
              <TableCell>{client.email}</TableCell>
              <TableCell>{client.mobile}</TableCell>
              <TableCell>{client.address}</TableCell>
              <TableCell>{client.notes}</TableCell>
              <TableCell>
                <IconButton color="primary" onClick={() => handleView(client)}>
                  <VisibilityIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => handleEdit(client)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={() => handleDelete(client.client_id)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {clientData.client_id ? 'Edit Client' : 'Add Client'}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="client_name"
            label="Client Name"
            type="text"
            fullWidth
            value={clientData.client_name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={clientData.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="mobile"
            label="Mobile"
            type="text"
            fullWidth
            value={clientData.mobile}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="address"
            label="Address"
            type="text"
            fullWidth
            value={clientData.address}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="notes"
            label="Notes"
            type="text"
            fullWidth
            value={clientData.notes}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClientsList;
