import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Container,
  Box,
  Card,
  CardContent,
  Avatar,
  Typography,
  // Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { deepPurple } from '@mui/material/colors'; // لإضافة لون مخصص للخلفية
const apiUrl = process.env.REACT_APP_API_URL;

const UserDetails = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get(`${apiUrl}/users/${userId}`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error('Failed to fetch user details:', error);
      });
  }, [userId]);

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        height: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="xs">
        {user ? (
          <Card sx={{ borderRadius: '15px', textAlign: 'center', padding: 3 }}>
            <CardContent>
              <Avatar
                sx={{
                  bgcolor: deepPurple[500], // إضافة لون مخصص للخلفية
                  width: 100,
                  height: 100,
                  margin: 'auto',
                  mb: 2,
                  fontSize: 40, // حجم الخط للرمز في الأفاتار
                }}
              >
                {user.first_name.charAt(0)}
                {user.last_name.charAt(0)}
              </Avatar>

              <Typography variant="h4">
                {user.first_name} {user.last_name}
              </Typography>
              <Typography color="textSecondary" sx={{ mb: 2 }}>
                <a
                  href={`mailto:${user.email}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {user.email}
                </a>
              </Typography>
              <Typography color="textSecondary" sx={{ mb: 2 }}>
                {user.phone}
              </Typography>
              <Typography color="textSecondary" sx={{ mb: 2 }}>
                {user.address}
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <Typography variant="body1">{t('loading')}</Typography>
        )}
      </Container>
    </Box>
  );
};

export default UserDetails;
