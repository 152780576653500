import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
const apiUrl = process.env.REACT_APP_API_URL;
const UserEdit = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  });

  const [originalUser, setOriginalUser] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    axios
      .get(`${apiUrl}/users/${userId}`)
      .then((response) => {
        setUser(response.data);
        setOriginalUser(response.data); // تخزين البيانات الأصلية للمقارنة
      })
      .catch((error) => {
        console.error('Failed to fetch user details:', error);
        setError(t('failed_to_fetch_user_details'));
      });
  }, [userId, t]); // أضف 't' إلى قائمة التبعيات

  const checkEmail = async () => {
    try {
      const encodedEmail = user.email;
      const emailCheck = await axios.get(
        `${apiUrl}/users-check-email`, // استخدام المسار الجديد
        {
          params: { email: encodedEmail },
        }
      );

      if (emailCheck.data.user_id && emailCheck.data.user_id !== userId) {
        setError(t('email_already_exists'));
        return false;
      }

      return true;
    } catch (error) {
      console.error('Failed to check email:', error);
      setError(t('failed_to_check_email'));
      return false;
    }
  };

  const checkPhone = async () => {
    try {
      const phoneCheck = await axios.get(
        `${apiUrl}/users-check-phone`, // استخدام المسار الجديد
        {
          params: { phone: user.phone },
        }
      );

      if (phoneCheck.data.user_id && phoneCheck.data.user_id !== userId) {
        setError(t('phone_already_exists'));
        return false;
      }

      return true;
    } catch (error) {
      console.error('Failed to check phone:', error);
      setError(t('failed_to_check_phone'));
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // التحقق مما إذا كان البريد الإلكتروني أو رقم الهاتف قد تغير
    const emailChanged = user.email !== originalUser.email;
    const phoneChanged = user.phone !== originalUser.phone;

    if (emailChanged) {
      const isEmailValid = await checkEmail();
      if (!isEmailValid) return;
    }

    if (phoneChanged) {
      const isPhoneValid = await checkPhone();
      if (!isPhoneValid) return;
    }

    try {
      await axios.put(`${apiUrl}/users/${userId}`, user);
      setSuccess(t('user_updated_successfully'));
      setError('');
      navigate(`/user-details/${userId}`);
    } catch (error) {
      console.error('Failed to update user:', error);
      setError(t('failed_to_update_user'));
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {t('edit_user')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label={t('username')}
            name="username"
            value={user.username}
            onChange={handleChange}
            disabled
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="first_name"
            label={t('first_name')}
            name="first_name"
            value={user.first_name}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="last_name"
            label={t('last_name')}
            name="last_name"
            value={user.last_name}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('email')}
            name="email"
            value={user.email}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            label={t('phone')}
            name="phone"
            value={user.phone}
            onChange={handleChange}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 3,
              mb: 2,
            }}
          >
            <Button type="submit" variant="contained" sx={{ width: '48%' }}>
              {t('save_changes')}
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/user-list')} // استبدل '/previous-page' بالمسار المناسب
              sx={{ width: '48%' }}
            >
              {t('cancel')}
            </Button>
          </Box>

          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>
      </Box>
    </Container>
  );
};

export default UserEdit;
