import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_URL;

const RoleList = () => {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [editRoleId, setEditRoleId] = useState(null);
  const [deleteRoleId, setDeleteRoleId] = useState(null);
  const [roleName, setRoleName] = useState("");
  const [roleNameAr, setRoleNameAr] = useState("");
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = () => {
    axios
      .get(`${apiUrl}/roles`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => console.error("Error fetching roles:", error));
  };

  const handleAdd = () => {
    axios
      .post(`${apiUrl}/roles`, {
        role_name: roleName,
        role_name_ar: roleNameAr,
      })
      .then(() => {
        setIsAddDialogOpen(false);
        fetchRoles();
      })
      .catch((error) => console.error("Error adding role:", error));
  };

  const handleEdit = () => {
    axios
      .put(`${apiUrl}/roles/${editRoleId}`, {
        role_name: roleName,
        role_name_ar: roleNameAr,
      })
      .then(() => {
        setEditRoleId(null);
        fetchRoles();
      })
      .catch((error) => console.error("Error updating role:", error));
  };

  const handleDelete = () => {
    axios
      .delete(`${apiUrl}/roles/${deleteRoleId}`)
      .then(() => {
        setDeleteRoleId(null);
        fetchRoles();
      })
      .catch((error) => console.error("Error deleting role:", error));
  };

  const openEditDialog = (role) => {
    setEditRoleId(role.role_id);
    setRoleName(role.role_name);
    setRoleNameAr(role.role_name_ar);
  };

  return (
    <Container>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setIsAddDialogOpen(true)}
        style={{ marginTop: 16, alignSelf: "flex-start" }}
      >
        {t("add_role")}
      </Button>
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">{t("role_name_english")}</TableCell>
              <TableCell align="center">{t("role_name_arabic")}</TableCell>
              <TableCell align="center">{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.length > 0 ? (
              roles.map((role, index) => (
                <TableRow key={role.role_id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{role.role_name}</TableCell>
                  <TableCell align="center">{role.role_name_ar}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => openEditDialog(role)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setDeleteRoleId(role.role_id)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t("no_roles_found")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add/Edit Dialog */}
      <Dialog
        open={Boolean(editRoleId) || isAddDialogOpen}
        onClose={() => {
          setEditRoleId(null);
          setIsAddDialogOpen(false);
        }}
      >
        <DialogTitle>{editRoleId ? t("edit_role") : t("add_role")}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t("role_name_english")}
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label={t("role_name_arabic")}
            value={roleNameAr}
            onChange={(e) => setRoleNameAr(e.target.value)}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditRoleId(null);
              setIsAddDialogOpen(false);
            }}
            color="primary"
          >
            {t("cancel")}
          </Button>
          <Button onClick={editRoleId ? handleEdit : handleAdd} color="secondary">
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={Boolean(deleteRoleId)}
        onClose={() => setDeleteRoleId(null)}
      >
        <DialogTitle>{t("delete_confirmation")}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteRoleId(null)} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleDelete} color="secondary">
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RoleList;
