import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Checkbox,
  Box,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { useCallback } from 'react';
const apiUrl = process.env.REACT_APP_API_URL;

const VetClinicRecordOutside = () => {
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const [formData, setFormData] = useState({
    record_date: getCurrentDate(),
    horse_id: '',
    rfid_chip_number: '',
    horse_name: '',
    client_id: '', // استبدال owner_id بـ client_id
    client_name: '',
    contact_info: '',
    procedures: [],
    medications: [],
    notes: '',
    total_amount: 0,
    file_uploads: [],
    payment_type_id: '',
  });

  const [procedures, setProcedures] = useState([]);
  const [medications, setMedications] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [horses, setHorses] = useState([]);
  const [errors, setErrors] = useState({});

  const calculateTotalAmount = useCallback(() => {
    const proceduresTotal = formData.procedures.reduce((sum, procId) => {
      const proc = procedures.find((p) => p.procedure_id === parseInt(procId));
      return sum + (proc?.price || 0);
    }, 0);

    const medicationsTotal = formData.medications.reduce(
      (sum, med) => sum + (med.price || 0),
      0
    );

    setFormData((prevState) => ({
      ...prevState,
      total_amount: proceduresTotal + medicationsTotal,
    }));
  }, [formData.procedures, formData.medications, procedures]);

  useEffect(() => {
    calculateTotalAmount();
  }, [formData.procedures, formData.medications, calculateTotalAmount]);

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = async () => {
    try {
      const [
        proceduresResponse,
        medicationsResponse,
        paymentTypesResponse,
        horsesResponse,
      ] = await Promise.all([
        axios.get(`${apiUrl}/procedures`),
        axios.get(`${apiUrl}/medications`),
        axios.get(`${apiUrl}/payment-types`),
        axios.get(`${apiUrl}/vetclinic-horses`), // تعديل المسار لجلب بيانات الخيل
      ]);

      if (
        proceduresResponse.data &&
        medicationsResponse.data &&
        paymentTypesResponse.data &&
        horsesResponse.data
      ) {
        setProcedures(
          proceduresResponse.data.sort((a, b) =>
            a.procedure_name.localeCompare(b.procedure_name)
          )
        );
        setMedications(medicationsResponse.data);
        setPaymentTypes(paymentTypesResponse.data);
        setHorses(horsesResponse.data);
      } else {
        console.error('One or more responses returned empty data');
      }
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
    }
  };

  const fetchHorseAndClientData = async (horse_id) => {
    try {
      // First, fetch the horse data to get the client_id
      const horseResponse = await axios.get(
        `${apiUrl}/vetclinic-horses/${horse_id}`
      );
      const horseData = horseResponse.data;

      // Now, use the client_id from the horse data to fetch the client details
      if (horseData.client_id) {
        const clientResponse = await axios.get(
          `${apiUrl}/vetclinic-clients/${horseData.client_id}`
        );
        const clientData = clientResponse.data;

        // Set the data to your form state
        setFormData((prevState) => ({
          ...prevState,
          horse_name: horseData.horse_name,
          rfid_chip_number: horseData.rfid_chip_number,
          client_name: clientData.client_name,
          contact_info: clientData.contact_info,
          client_id: horseData.client_id, // Keep the client_id for further operations
        }));
      } else {
        console.error('No client_id associated with this horse');
        // Handle the case where there is no client associated with the horse
      }
    } catch (error) {
      console.error('Error fetching horse and client data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    if (name === 'horse_id') {
      fetchHorseAndClientData(value);
    }
  };

  const handleProcedureChange = (procedure_id) => {
    setFormData((prevState) => {
      const isChecked = prevState.procedures.includes(procedure_id);
      const updatedProcedures = isChecked
        ? prevState.procedures.filter((id) => id !== procedure_id)
        : [...prevState.procedures, procedure_id];

      setErrors((prevErrors) => ({
        ...prevErrors,
        procedures: updatedProcedures.length > 0 ? '' : prevErrors.procedures,
      }));

      return {
        ...prevState,
        procedures: updatedProcedures,
      };
    });
  };

  const handleMedicationChange = (index, field, value) => {
    setFormData((prevState) => {
      const updatedMedications = prevState.medications.map((med, i) => {
        if (i === index) {
          const updatedMed = { ...med, [field]: value };

          if (field === 'medication_id') {
            const selectedMed = medications.find(
              (m) => m.medication_id === parseInt(value)
            );
            if (selectedMed) {
              updatedMed.dosage = selectedMed.default_dosage || '';
              updatedMed.quantity = selectedMed.default_quantity || 1;
              updatedMed.unit = selectedMed.default_unit || '';
              updatedMed.price = selectedMed.price || 0;
            }
          }

          return updatedMed;
        }
        return med;
      });

      return {
        ...prevState,
        medications: updatedMedications,
      };
    });
  };

  const addMedication = () => {
    setFormData((prevState) => ({
      ...prevState,
      medications: [
        ...prevState.medications,
        { medication_id: '', dosage: '', quantity: '', unit: '', price: 0 },
      ],
    }));
  };

  const removeMedication = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      medications: prevState.medications.filter((_, i) => i !== index),
    }));
  };

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadPromises = files.map((file) => {
      const uploadFormData = new FormData();
      uploadFormData.append('file', file);

      return axios.post(`${apiUrl}/vetclinic-records/upload`, uploadFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    });

    try {
      const responses = await Promise.all(uploadPromises);
      const uploadedFiles = responses.map((response) => response.data.filePath);

      setFormData((prevState) => ({
        ...prevState,
        file_uploads: uploadedFiles,
      }));
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const validateFormData = () => {
    const newErrors = {};

    if (!formData.horse_id) newErrors.horse_id = 'Horse is required.';
    if (formData.procedures.length === 0)
      newErrors.procedures = 'At least one procedure must be selected.';
    if (!formData.payment_type_id)
      newErrors.payment_type_id = 'Payment type is required.';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFormData()) {
      return;
    }

    console.log('FormData to be submitted:', formData);
    try {
      await axios.post(`${apiUrl}/vetclinic-records-outside`, {
        // تغيير المسار الخاص بإرسال البيانات
        ...formData,
        work_place: 'Outside', // تحديد أن المكان هو خارج المستشفى
        procedures: formData.procedures.map((procId) => {
          const proc = procedures.find(
            (p) => p.procedure_id === parseInt(procId)
          );
          return { procedure_id: procId, price: proc ? proc.price : 0 };
        }),
        medications: formData.medications.map((med) => ({
          ...med,
          price: med.price || 0,
        })),
      });
      alert('Record added successfully!');
      setFormData({
        record_date: getCurrentDate(),
        horse_id: '',
        rfid_chip_number: '',
        horse_name: '',
        client_id: '', // إعادة تعيين client_id بدلاً من owner_id
        client_name: '',
        contact_info: '',
        procedures: [],
        medications: [],
        total_amount: 0,
        file_uploads: [],
        payment_type_id: '',
        notes: '',
      });
      setErrors({});
    } catch (error) {
      console.error('Error adding record:', error);
      alert('Failed to add record. Please check your data and try again.');
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="center" mt={3} mb={2}>
        <Typography variant="h4">Vet Clinic Record Form (Outside)</Typography>
      </Box>

      <TextField
        label="Date"
        type="date"
        name="record_date"
        value={formData.record_date}
        fullWidth
        margin="normal"
        disabled
      />

      <FormControl fullWidth margin="normal" error={!!errors.horse_id}>
        <InputLabel>Horse</InputLabel>
        <Select
          name="horse_id"
          value={formData.horse_id}
          onChange={handleChange}
        >
          {horses.map((horse) => (
            <MenuItem key={horse.horse_id} value={horse.horse_id}>
              {horse.horse_name}{' '}
              {/* تعديل الحقل ليتماشى مع اسم الحصان الجديد */}
            </MenuItem>
          ))}
        </Select>
        {errors.horse_id && (
          <Typography color="error">{errors.horse_id}</Typography>
        )}
      </FormControl>

      <TextField
        label="RFID Chip Number"
        name="rfid_chip_number"
        value={formData.rfid_chip_number}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Client Name"
        name="client_name"
        value={formData.client_name} // استبدال owner_name بـ client_name
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Contact Info"
        name="contact_info"
        value={formData.contact_info} // استبدال owner_phone بـ contact_info
        fullWidth
        margin="normal"
        disabled
      />

      <FormControl component="fieldset" margin="normal">
        <Typography>Procedures</Typography>
        <Grid container spacing={2}>
          {procedures.map((procedure) => (
            <Grid item xs={12} sm={6} md={4} key={procedure.procedure_id}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={procedure.procedure_id}
                    checked={formData.procedures.includes(
                      procedure.procedure_id
                    )}
                    onChange={() =>
                      handleProcedureChange(procedure.procedure_id)
                    }
                  />
                }
                label={procedure.procedure_name}
              />
            </Grid>
          ))}
        </Grid>
        {errors.procedures && (
          <Typography color="error">{errors.procedures}</Typography>
        )}
      </FormControl>

      <FormControl
        component="fieldset"
        margin="dense"
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        <Typography>Medications</Typography>
        {formData.medications.map((medication, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <Select
              name="medication_id"
              value={medication.medication_id}
              onChange={(e) =>
                handleMedicationChange(index, 'medication_id', e.target.value)
              }
              fullWidth
            >
              {medications.map((med) => (
                <MenuItem key={med.medication_id} value={med.medication_id}>
                  {med.medication_name}
                </MenuItem>
              ))}
            </Select>

            <TextField
              label="Dosage"
              name="dosage"
              value={medication.dosage}
              onChange={(e) =>
                handleMedicationChange(index, 'dosage', e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Quantity"
              type="number"
              name="quantity"
              value={medication.quantity}
              onChange={(e) =>
                handleMedicationChange(index, 'quantity', e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Unit"
              name="unit"
              value={medication.unit}
              onChange={(e) =>
                handleMedicationChange(index, 'unit', e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => removeMedication(index)}
            >
              Remove Medication
            </Button>
          </div>
        ))}
        <Button variant="contained" color="primary" onClick={addMedication}>
          Add Medication
        </Button>
      </FormControl>

      <TextField
        label="Notes"
        name="notes"
        value={formData.notes}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Total Amount"
        type="number"
        name="total_amount"
        value={formData.total_amount}
        onChange={handleChange}
        fullWidth
        margin="normal"
        disabled
      />

      <input type="file" multiple onChange={handleFileUpload} />

      <FormControl fullWidth margin="normal" error={!!errors.payment_type_id}>
        <InputLabel>Payment Type</InputLabel>
        <Select
          name="payment_type_id"
          value={formData.payment_type_id}
          onChange={handleChange}
        >
          {paymentTypes.map((type) => (
            <MenuItem key={type.payment_type_id} value={type.payment_type_id}>
              {type.payment_type_name}
            </MenuItem>
          ))}
        </Select>
        {errors.payment_type_id && (
          <Typography color="error">{errors.payment_type_id}</Typography>
        )}
      </FormControl>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 2,
        }}
      >
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </div>
  );
};

export default VetClinicRecordOutside;
