import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Avatar,
  IconButton,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { IoIosMale } from 'react-icons/io';
import { IoIosFemale } from 'react-icons/io';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
const apiUrl = process.env.REACT_APP_API_URL;

const Home = () => {
  const { t, i18n } = useTranslation();
  const [horses, setHorses] = useState([]);
  const [rooms, setRooms] = useState({});
  const [error, setError] = useState('');
  const [genders, setGenders] = useState({
    S: t('stallion'),
    M: t('mare'),
  });
  const [colors, setColors] = useState({});
  const [breeds, setBreeds] = useState({});
  const [stats, setStats] = useState({
    totalHorses: 0,
    stallions: 0,
    mares: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const ownerId = localStorage.getItem('owner_id');

        if (!ownerId) {
          setError(t('error_fetching_owner_id'));
          return;
        }

        const horsesResponse = await axios.get(
          `${apiUrl}/owners/${ownerId}/horses`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const horsesData = horsesResponse.data;

        setHorses(horsesData);

        // Calculate statistics
        const totalHorses = horsesData.length;
        const stallions = horsesData.filter(
          (horse) => horse.gender === 'S'
        ).length;
        const mares = horsesData.filter((horse) => horse.gender === 'M').length;

        setStats({
          totalHorses,
          stallions,
          mares,
        });

        const roomsMap = {};
        for (const horse of horsesData) {
          const roomResponse = await axios.get(
            `${apiUrl}/rooms-by-horse_id?horse_id=${horse.horse_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (roomResponse.data.length > 0) {
            const room = roomResponse.data[0];
            roomsMap[horse.horse_id] = {
              stableName: room.stable_name,
              roomName: room.room_name,
              displayName: room.room_name
                ? `${room.stable_name} - ${room.room_name}`
                : t('room_name_not_available'),
            };
          }
        }
        setRooms(roomsMap);

        const colorsResponse = await axios.get(`${apiUrl}/horse_colors`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const colorsMap = {};
        colorsResponse.data.forEach((color) => {
          colorsMap[color.color_id] =
            i18n.language === 'ar' ? color.color_arabic : color.color_english;
        });
        setColors(colorsMap);

        const breedsResponse = await axios.get(`${apiUrl}/horse_breeds`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const breedsMap = {};
        breedsResponse.data.forEach((breed) => {
          breedsMap[breed.breed_id] =
            i18n.language === 'ar' ? breed.breed_arabic : breed.breed_english;
        });
        setBreeds(breedsMap);
      } catch (err) {
        console.error('Failed to fetch data: ' + err.message);
        setError(t('error_fetching_horses'));
      }
    };

    fetchData();
  }, [t, i18n.language]);

  useEffect(() => {
    setGenders({
      S: i18n.language === 'ar' ? 'حصان' : 'Stallion',
      M: i18n.language === 'ar' ? 'فرس' : 'Mare',
    });
  }, [i18n.language]);

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}/${month}/${day}`;
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
    >
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          {t('my_horse_details')}
        </Typography>
        {/* Statistics Section */}
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={6} sm={4}>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  <Typography variant="h6" align="center">
                    {t('total_horses')}
                  </Typography>
                </Box>
                <Typography variant="h4" align="center">
                  {stats.totalHorses}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} sm={4}>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  <IoIosMale fontSize="large" />
                  <Typography variant="h6" align="center">
                    {t('stallions')}
                  </Typography>
                </Box>
                <Typography variant="h4" align="center">
                  {stats.stallions}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={6} sm={4}>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  <IoIosFemale fontSize="large" />
                  <Typography variant="h6" align="center">
                    {t('mares')}
                  </Typography>
                </Box>
                <Typography variant="h4" align="center">
                  {stats.mares}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ marginTop: 2.5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">{t('horse_name')}</TableCell>
                <TableCell align="center">{t('gender')}</TableCell>
                <TableCell align="center">{t('date_of_birth')}</TableCell>
                <TableCell align="center">{t('stable_and_room')}</TableCell>
                <TableCell align="center">{t('breed')}</TableCell>
                <TableCell align="center">{t('color_id')}</TableCell>
                <TableCell align="center">{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {horses.map((horse, index) => (
                <TableRow key={horse.horse_id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: i18n.language === 'ar' ? 2 : 1,
                        minWidth: '200px',
                      }}
                    >
                      <Avatar sx={{ mr: 2 }}>{horse.name.charAt(0)}</Avatar>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="body2">
                          {i18n.language === 'ar'
                            ? horse.name_arabic
                            : horse.name || '-'}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {horse.rfid_chip_number || '-'}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {genders[horse.gender] || '-'}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(horse.date_of_birth)}
                  </TableCell>
                  <TableCell align="center">
                    {rooms[horse.horse_id]
                      ? rooms[horse.horse_id].displayName
                      : t('room_name_not_available')}
                  </TableCell>
                  <TableCell align="center">
                    {breeds[horse.breed_id] || '-'}
                  </TableCell>
                  <TableCell align="center">
                    {colors[horse.color_id] || '-'}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(`/horse-details/${horse.horse_id}`)
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default Home;
