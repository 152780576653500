import React from "react";
import { Link, Routes, Route } from "react-router-dom";
import UserRegistration from "./UserRegistration";
import HorseRegistration from "./HorseRegistration";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation(); // لاستخدام الترجمة

  return (
    <div>
      <h2>{t("dashboard_title")}</h2>
      <nav>
        <ul>
          <li>
            <Link to="register-user">{t("register_user")}</Link>
          </li>
          <li>
            <Link to="register-horse">{t("register_horse")}</Link>
          </li>
        </ul>
      </nav>

      <Routes>
        <Route path="register-user" element={<UserRegistration />} />
        <Route path="register-horse" element={<HorseRegistration />} />
      </Routes>
    </div>
  );
};

export default Dashboard;
