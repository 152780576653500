import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'; // استخدام الترجمة
const apiUrl = process.env.REACT_APP_API_URL;

const MedicationAdd = () => {
  const { t } = useTranslation(); // استخدام الترجمة
  const navigate = useNavigate();

  // State for medication details
  const [medication, setMedication] = useState({
    medication_name: '',
    default_dosage: '',
    default_unit: '',
  });

  // State for price
  const [price, setPrice] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission reloading the page

    const medicationData = {
      medication_name: medication.medication_name, // Correct reference
      default_dosage: medication.default_dosage, // Correct reference
      default_unit: medication.default_unit, // Correct reference
      price, // Correct reference
    };

    try {
      await axios.post(`${apiUrl}/medications`, medicationData);
      navigate('/medications'); // Redirect on success
    } catch (error) {
      console.error('Error adding medication:', error);
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5, textAlign: 'center' }}>
          {t('add_medication')}
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ width: '100%', maxWidth: 600, mt: 2 }}
        >
          <TextField
            label={t('medication_name')}
            value={medication.medication_name}
            onChange={(e) =>
              setMedication({ ...medication, medication_name: e.target.value })
            }
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t('default_dosage')}
            value={medication.default_dosage}
            onChange={(e) =>
              setMedication({ ...medication, default_dosage: e.target.value })
            }
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t('default_unit')}
            value={medication.default_unit}
            onChange={(e) =>
              setMedication({ ...medication, default_unit: e.target.value })
            }
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t('addMedication.price')}
            name="price"
            type="number"
            fullWidth
            variant="outlined"
            margin="normal"
            required
            value={price}
            onChange={(e) => setPrice(e.target.value)} // Correctly bind the price state
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginRight: 2 }}
            >
              {t('save')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/medications')}
            >
              {t('cancel')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MedicationAdd;
