import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Paper,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

const AddStable = () => {
  const [stableName, setStableName] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const handleAddStable = () => {
    axios
      .post(`${apiUrl}/stables`, { stable_name: stableName })
      .then((response) => {
        console.log('Stable added:', response.data);
        setOpenSnackbar(true); // عرض رسالة النجاح
        setTimeout(() => {
          navigate('/stables'); // الرجوع إلى قائمة الاسطبلات بعد الإضافة
        }, 2000);
      })
      .catch((error) => console.error('Error adding stable:', error));
  };

  return (
    <Container maxWidth="sm">
      <Paper style={{ padding: 32, marginTop: 32 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Add New Stable
        </Typography>
        <TextField
          label="Stable Name"
          value={stableName}
          onChange={(e) => setStableName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddStable}
          disabled={!stableName.trim()}
          fullWidth
          style={{ marginTop: 16 }}
        >
          Add Stable
        </Button>
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          Stable added successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddStable;
