import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
const apiUrl = process.env.REACT_APP_API_URL;

const HorseColorList = () => {
  const { t } = useTranslation();
  const [colors, setColors] = useState([]);
  const [editColorId, setEditColorId] = useState(null);
  const [deleteColorId, setDeleteColorId] = useState(null);
  const [colorArabic, setColorArabic] = useState("");
  const [colorEnglish, setColorEnglish] = useState("");
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  useEffect(() => {
    fetchColors();
  }, []);

  const fetchColors = () => {
    axios
      .get(`${apiUrl}/horse_colors`)
      .then((response) => {
        setColors(response.data);
      })
      .catch((error) => console.error("Error fetching colors:", error));
  };

  const handleAdd = () => {
    axios
      .post(`${apiUrl}/horse_colors`, {
        color_arabic: colorArabic,
        color_english: colorEnglish,
      })
      .then(() => {
        setIsAddDialogOpen(false);
        fetchColors();
      })
      .catch((error) => console.error("Error adding color:", error));
  };

  const handleEdit = () => {
    axios
      .put(`${apiUrl}/horse_colors/${editColorId}`, {
        color_arabic: colorArabic,
        color_english: colorEnglish,
      })
      .then(() => {
        setEditColorId(null);
        fetchColors();
      })
      .catch((error) => console.error("Error updating color:", error));
  };

  const handleDelete = () => {
    axios
      .delete(`${apiUrl}/horse_colors/${deleteColorId}`)
      .then(() => {
        setDeleteColorId(null);
        fetchColors();
      })
      .catch((error) => console.error("Error deleting color:", error));
  };

  const openEditDialog = (color) => {
    setEditColorId(color.color_id);
    setColorArabic(color.color_arabic);
    setColorEnglish(color.color_english);
  };

  return (
    <Container>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setIsAddDialogOpen(true)}
        style={{ marginTop: 16, alignSelf: "flex-start" }}
      >
        {t("add_color")}
      </Button>
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">{t("color_arabic")}</TableCell>
              <TableCell align="center">{t("color_english")}</TableCell>
              <TableCell align="center">{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {colors.length > 0 ? (
              colors.map((color, index) => (
                <TableRow key={color.color_id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{color.color_arabic}</TableCell>
                  <TableCell align="center">{color.color_english}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => openEditDialog(color)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setDeleteColorId(color.color_id)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t("no_colors_found")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add/Edit Dialog */}
      <Dialog
        open={Boolean(editColorId) || isAddDialogOpen}
        onClose={() => {
          setEditColorId(null);
          setIsAddDialogOpen(false);
        }}
      >
        <DialogTitle>
          {editColorId ? t("edit_color") : t("add_color")}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t("color_arabic")}
            value={colorArabic}
            onChange={(e) => setColorArabic(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label={t("color_english")}
            value={colorEnglish}
            onChange={(e) => setColorEnglish(e.target.value)}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditColorId(null);
              setIsAddDialogOpen(false);
            }}
            color="primary"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={editColorId ? handleEdit : handleAdd}
            color="secondary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={Boolean(deleteColorId)}
        onClose={() => setDeleteColorId(null)}
      >
        <DialogTitle>{t("delete_confirmation")}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteColorId(null)} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleDelete} color="secondary">
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default HorseColorList;
