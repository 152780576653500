import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
const apiUrl = process.env.REACT_APP_API_URL;

const HorseBreedList = () => {
  const { t } = useTranslation();
  const [breeds, setBreeds] = useState([]);
  const [editBreedId, setEditBreedId] = useState(null);
  const [deleteBreedId, setDeleteBreedId] = useState(null);
  const [breedArabic, setBreedArabic] = useState("");
  const [breedEnglish, setBreedEnglish] = useState("");
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  useEffect(() => {
    fetchBreeds();
  }, []);

  const fetchBreeds = () => {
    axios
      .get(`${apiUrl}/horse_breeds`)
      .then((response) => {
        setBreeds(response.data);
      })
      .catch((error) => console.error("Error fetching breeds:", error));
  };

  const handleAdd = () => {
    axios
      .post(`${apiUrl}/horse_breeds`, {
        breed_arabic: breedArabic,
        breed_english: breedEnglish,
      })
      .then(() => {
        setIsAddDialogOpen(false);
        fetchBreeds();
      })
      .catch((error) => console.error("Error adding breed:", error));
  };

  const handleEdit = () => {
    axios
      .put(`${apiUrl}/horse_breeds/${editBreedId}`, {
        breed_arabic: breedArabic,
        breed_english: breedEnglish,
      })
      .then(() => {
        setEditBreedId(null);
        fetchBreeds();
      })
      .catch((error) => console.error("Error updating breed:", error));
  };

  const handleDelete = () => {
    axios
      .delete(`${apiUrl}/horse_breeds/${deleteBreedId}`)
      .then(() => {
        setDeleteBreedId(null);
        fetchBreeds();
      })
      .catch((error) => console.error("Error deleting breed:", error));
  };

  const openEditDialog = (breed) => {
    setEditBreedId(breed.breed_id);
    setBreedArabic(breed.breed_arabic);
    setBreedEnglish(breed.breed_english);
  };

  return (
    <Container>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setIsAddDialogOpen(true)}
        style={{ marginTop: 16, alignSelf: "flex-start" }}
      >
        {t("add_breed")}
      </Button>
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">{t("breed_arabic")}</TableCell>
              <TableCell align="center">{t("breed_english")}</TableCell>
              <TableCell align="center">{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {breeds.length > 0 ? (
              breeds.map((breed, index) => (
                <TableRow key={breed.breed_id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{breed.breed_arabic}</TableCell>
                  <TableCell align="center">{breed.breed_english}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => openEditDialog(breed)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => setDeleteBreedId(breed.breed_id)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  {t("no_breeds_found")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add/Edit Dialog */}
      <Dialog
        open={Boolean(editBreedId) || isAddDialogOpen}
        onClose={() => {
          setEditBreedId(null);
          setIsAddDialogOpen(false);
        }}
      >
        <DialogTitle>
          {editBreedId ? t("edit_breed") : t("add_breed")}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t("breed_arabic")}
            value={breedArabic}
            onChange={(e) => setBreedArabic(e.target.value)}
            margin="dense"
          />
          <TextField
            fullWidth
            label={t("breed_english")}
            value={breedEnglish}
            onChange={(e) => setBreedEnglish(e.target.value)}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setEditBreedId(null);
              setIsAddDialogOpen(false);
            }}
            color="primary"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={editBreedId ? handleEdit : handleAdd}
            color="secondary"
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={Boolean(deleteBreedId)}
        onClose={() => setDeleteBreedId(null)}
      >
        <DialogTitle>{t("delete_confirmation")}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteBreedId(null)} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleDelete} color="secondary">
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default HorseBreedList;
