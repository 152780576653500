import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Container,
  Box,
  Alert,
  FormHelperText,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const apiUrl = process.env.REACT_APP_API_URL;

const UserRegistration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role_id, setRoleId] = useState('');
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');

  useEffect(() => {
    axios
      .get(`${apiUrl}/roles`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.error('Failed to fetch roles:', error);
      });
  }, []);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateFields = () => {
    let isValid = true;
    let errors = {};

    if (!username) {
      isValid = false;
      errors.username = t('username_required');
    }

    if (!password) {
      isValid = false;
      errors.password = t('password_required');
    }

    if (!first_name) {
      isValid = false;
      errors.first_name = t('first_name_required');
    }

    if (!last_name) {
      isValid = false;
      errors.last_name = t('last_name_required');
    }

    if (!email) {
      isValid = false;
      errors.email = t('email_required');
    } else if (!validateEmail(email)) {
      isValid = false;
      errors.email = t('invalid_email_format');
    }

    if (!phone) {
      isValid = false;
      errors.phone = t('phone_required');
    }

    if (!role_id) {
      isValid = false;
      errors.role_id = t('role_required');
    }

    setError(errors);
    return isValid;
  };

  const checkEmailAndPhone = async () => {
    try {
      const emailCheck = await axios.get(`${apiUrl}/users-check-email`, {
        params: { email },
      });

      if (emailCheck.data.user_id) {
        setError((prevErrors) => ({
          ...prevErrors,
          email: t('email_already_exists'),
        }));
        return false;
      }

      const phoneCheck = await axios.get(`${apiUrl}/users-check-phone`, {
        params: { phone },
      });

      if (phoneCheck.data.user_id) {
        setError((prevErrors) => ({
          ...prevErrors,
          phone: t('phone_already_exists'),
        }));
        return false;
      }

      return true;
    } catch (error) {
      console.error('Failed to check email or phone:', error);
      setError((prevErrors) => ({
        ...prevErrors,
        global: t('failed_to_check_email_or_phone'),
      }));
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fieldsValid = validateFields();
    if (!fieldsValid) return;

    const isValid = await checkEmailAndPhone();
    if (!isValid) return;

    try {
      await axios.post(`${apiUrl}/users`, {
        username,
        password,
        first_name,
        last_name,
        email,
        phone,
        role_id,
      });

      setSuccess(t('user_registered_successfully'));
      setError({});
      navigate('/user-list');
    } catch (error) {
      console.error('Error:', error);
      setError({
        global:
          t('failed_to_register_user') +
          ': ' +
          (error.response ? error.response.data : error.message),
      });
      setSuccess('');
    }
  };

  const handleFieldChange = (setter) => (e) => {
    setter(e.target.value); // يقوم بتحديث قيمة الحقل بناءً على القيمة الجديدة المدخلة
    setError((prevErrors) => ({
      ...prevErrors, // يحتفظ بالأخطاء السابقة للحقل الأخرى
      [e.target.name]: '', // يقوم بمسح رسالة الخطأ الخاصة بالحقل الحالي فقط
    }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {t('register')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label={t('username')}
            name="username"
            autoComplete="off" // Prevents browser autofill
            autoFocus
            value={username}
            onChange={handleFieldChange(setUsername)}
            error={Boolean(error.username)}
            helperText={error.username}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('password')}
            type={showPassword ? 'text' : 'password'} // عرض/إخفاء كلمة المرور
            id="password"
            value={password}
            onChange={handleFieldChange(setPassword)}
            error={Boolean(error.password)}
            helperText={error.password}
            autoComplete="new-password" // تعطيل الإكمال التلقائي لكلمة المرور
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="first_name"
            label={t('first_name')}
            name="first_name"
            autoComplete="off" // Prevents browser autofill
            value={first_name}
            onChange={handleFieldChange(setFirstName)}
            error={Boolean(error.first_name)}
            helperText={error.first_name}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="last_name"
            label={t('last_name')}
            name="last_name"
            autoComplete="off" // Prevents browser autofill
            value={last_name}
            onChange={handleFieldChange(setLastName)}
            error={Boolean(error.last_name)}
            helperText={error.last_name}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('email')}
            name="email"
            autoComplete="off" // Prevents browser autofill
            value={email}
            onChange={handleFieldChange(setEmail)}
            error={Boolean(error.email)}
            helperText={error.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            label={t('phone')}
            name="phone"
            autoComplete="off" // Prevents browser autofill
            value={phone}
            onChange={handleFieldChange(setPhone)}
            error={Boolean(error.phone)}
            helperText={error.phone}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="role-label">{t('role')}</InputLabel>
            <Select
              labelId="role-label"
              id="role_id"
              value={role_id}
              label={t('role')}
              onChange={(e) => {
                setRoleId(e.target.value);
                setError((prevErrors) => ({
                  ...prevErrors,
                  role_id: '', // Clear the role_id error
                }));
              }}
              error={Boolean(error.role_id)}
            >
              {roles.map((role) => (
                <MenuItem key={role.role_id} value={role.role_id}>
                  {`${role.role_name} | ${role.role_name_ar}`}
                </MenuItem>
              ))}
            </Select>
            {error.role_id && (
              <FormHelperText error>{error.role_id}</FormHelperText>
            )}
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 3,
              mb: 2,
            }}
          >
            <Button type="submit" variant="contained" sx={{ width: '48%' }}>
              {t('register')}
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/user-list')} // استبدل '/previous-page' بالمسار المناسب
              sx={{ width: '48%' }}
            >
              {t('cancel')}
            </Button>
          </Box>

          {error.global && <Alert severity="error">{error.global}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>
      </Box>
    </Container>
  );
};

export default UserRegistration;
