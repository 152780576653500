import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Checkbox,
  Box,
  FormControlLabel,
  Grid,
} from '@mui/material';
const apiUrl = process.env.REACT_APP_API_URL;

const VetClinicRecordInside = () => {
  // الحصول على تاريخ اليوم في تنسيق YYYY-MM-DD
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const [formData, setFormData] = useState({
    record_date: getCurrentDate(),
    horse_id: '',
    rfid_chip_number: '',
    horse_name: '',
    owner_name: '',
    owner_phone: '',
    procedures: [],
    medications: [],
    total_amount: '',
    file_uploads: [],
    payment_type_id: '',
  });

  const [procedures, setProcedures] = useState([]);
  const [medications, setMedications] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [horses, setHorses] = useState([]);

  // استخدام useEffect لجلب البيانات عند تحميل الصفحة
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchHorseAndOwnerData = async (horse_id) => {
    try {
      const response = await axios.get(
        `${apiUrl}/vetclinic-records-owner/${horse_id}`
      );
      const data = response.data;

      setFormData((prevState) => ({
        ...prevState,
        horse_name: data.combined_name, // استخدام الاسم المدمج الجديد
        rfid_chip_number: data.rfid_chip_number,
        owner_name: data.owner_name, // استرجاع اسم المالك
        owner_phone: data.owner_phone, // استرجاع رقم الهاتف
      }));
    } catch (error) {
      console.error('Error fetching horse and owner data:', error);
    }
  };

  // جلب البيانات من السيرفر
  const fetchDropdownData = async () => {
    try {
      const proceduresResponse = await axios.get(`${apiUrl}/procedures`);
      const medicationsResponse = await axios.get(`${apiUrl}/medications`);
      const paymentTypesResponse = await axios.get(`${apiUrl}/payment-types`);
      const horsesResponse = await axios.get(`${apiUrl}/horses`);

      if (
        proceduresResponse.data &&
        medicationsResponse.data &&
        paymentTypesResponse.data &&
        horsesResponse.data
      ) {
        const sortedProcedures = proceduresResponse.data.sort((a, b) =>
          a.procedure_name.localeCompare(b.procedure_name)
        );

        setProcedures(sortedProcedures);
        setMedications(medicationsResponse.data);
        setPaymentTypes(paymentTypesResponse.data);
        setHorses(horsesResponse.data);
      } else {
        console.error('One or more responses returned empty data');
      }
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
    }
  };

  // معالجة تغيير المدخلات في النموذج
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'horse_id') {
      setFormData({ ...formData, horse_id: value });
      fetchHorseAndOwnerData(value); // استدعاء الدالة لجلب بيانات الحصان والمالك
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // معالجة اختيار الإجراءات
  const handleProcedureChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({ ...formData, procedures: [...formData.procedures, value] });
    } else {
      setFormData({
        ...formData,
        procedures: formData.procedures.filter((id) => id !== value),
      });
    }
  };

  // معالجة تغيير الأدوية
  const handleMedicationChange = (index, field, value) => {
    const updatedMedications = formData.medications.map((med, i) =>
      i === index ? { ...med, [field]: value } : med
    );
    setFormData({ ...formData, medications: updatedMedications });
  };

  // إضافة دواء جديد
  const addMedication = () => {
    setFormData({
      ...formData,
      medications: [
        ...formData.medications,
        { medication_id: '', dosage: '', quantity: '', unit: '' },
      ],
    });
  };

  // إزالة دواء من القائمة
  const removeMedication = (index) => {
    setFormData({
      ...formData,
      medications: formData.medications.filter((_, i) => i !== index),
    });
  };

  // معالجة رفع الملفات
  const handleFileUpload = async (e) => {
    const files = e.target.files;
    let uploadPromises = [];
    let uploadedFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const uploadFormData = new FormData();
      uploadFormData.append('file', file);

      const uploadPromise = axios.post(
        `${apiUrl}/vetclinic-records/upload`,
        uploadFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      uploadPromises.push(uploadPromise);
    }

    try {
      const responses = await Promise.all(uploadPromises);
      responses.forEach((response) => {
        uploadedFiles.push(response.data.filePath);
      });
      setFormData({ ...formData, file_uploads: uploadedFiles });
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  // معالجة إرسال النموذج
  const handleSubmit = async () => {
    try {
      await axios.post(`${apiUrl}/vetclinic-records`, {
        ...formData,
        work_place: 'Inside', // تعيين مكان العمل بشكل ثابت على "Inside"
      });
      alert('Record added successfully!');
      // إعادة تعيين الحقول بعد الإرسال
      setFormData({
        record_date: getCurrentDate(),
        horse_id: '',
        rfid_chip_number: '',
        horse_name: '',
        procedures: [],
        medications: [],
        total_amount: '',
        file_uploads: [],
        payment_type_id: '',
      });
    } catch (error) {
      console.error('Error adding record:', error);
    }
  };

  return (
    <div>
      <Box textAlign="center" mb={4}>
        <Typography variant="h4">Vet Clinic Record Form (Inside)</Typography>
      </Box>

      <TextField
        label="Date"
        type="date"
        name="record_date"
        value={formData.record_date}
        fullWidth
        margin="normal"
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Horse</InputLabel>
        <Select
          name="horse_id"
          value={formData.horse_id}
          onChange={handleChange}
        >
          {horses.map((horse) => (
            <MenuItem key={horse.horse_id} value={horse.horse_id}>
              {horse.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="RFID Chip Number"
        name="rfid_chip_number"
        value={formData.rfid_chip_number}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Owner Name"
        name="owner_name"
        value={formData.owner_name}
        fullWidth
        margin="normal"
        disabled // جعل الحقل غير قابل للتحرير
      />

      <TextField
        label="Owner Phone"
        name="owner_phone"
        value={formData.owner_phone}
        fullWidth
        margin="normal"
        disabled // جعل الحقل غير قابل للتحرير
      />

      <FormControl component="fieldset" margin="normal">
        <Typography>Procedures</Typography>
        <Grid container spacing={2}>
          {procedures.map((procedure) => (
            <Grid item xs={12} sm={6} md={4} key={procedure.procedure_id}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={procedure.procedure_id}
                    onChange={handleProcedureChange}
                  />
                }
                label={procedure.procedure_name}
              />
            </Grid>
          ))}
        </Grid>
      </FormControl>

      <FormControl
        component="fieldset"
        margin="dense"
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        <Typography>Medications</Typography>
        {formData.medications.map((medication, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <Select
              name="medication_id"
              value={medication.medication_id}
              onChange={(e) => {
                const selectedMed = medications.find(
                  (med) => med.medication_id === e.target.value
                );

                if (selectedMed) {
                  const updatedMedications = formData.medications.map(
                    (med, i) =>
                      i === index
                        ? {
                            ...med,
                            medication_id: selectedMed.medication_id,
                            dosage: selectedMed.default_dosage || '',
                            quantity: selectedMed.default_quantity || 1,
                            unit: selectedMed.default_unit || '',
                          }
                        : med
                  );
                  setFormData({ ...formData, medications: updatedMedications });
                }
              }}
              fullWidth
            >
              {medications.map((med) => (
                <MenuItem key={med.medication_id} value={med.medication_id}>
                  {med.medication_name}
                </MenuItem>
              ))}
            </Select>

            <TextField
              label="Dosage"
              name="dosage"
              value={medication.dosage}
              onChange={(e) =>
                handleMedicationChange(index, 'dosage', e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Quantity"
              type="number"
              name="quantity"
              value={medication.quantity}
              onChange={(e) =>
                handleMedicationChange(index, 'quantity', e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Unit"
              name="unit"
              value={medication.unit}
              onChange={(e) =>
                handleMedicationChange(index, 'unit', e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => removeMedication(index)}
            >
              Remove Medication
            </Button>
          </div>
        ))}
        <Button variant="contained" color="primary" onClick={addMedication}>
          Add Medication
        </Button>
      </FormControl>

      <TextField
        label="Total Amount"
        type="number"
        name="total_amount"
        value={formData.total_amount}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />

      <input type="file" multiple onChange={handleFileUpload} />

      <FormControl fullWidth margin="normal">
        <InputLabel>Payment Type</InputLabel>
        <Select
          name="payment_type_id"
          value={formData.payment_type_id}
          onChange={handleChange}
        >
          {paymentTypes.map((type) => (
            <MenuItem key={type.payment_type_id} value={type.payment_type_id}>
              {type.payment_type_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default VetClinicRecordInside;
