import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
} from '@mui/material';
import { useParams } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const RecordDetails = () => {
  const { record_id } = useParams();
  const [recordData, setRecordData] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRecordDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/vetclinic-records/${record_id}`
        );
        setRecordData(response.data);
      } catch (err) {
        console.error('Failed to fetch record details:', err);
        setError('Failed to load record details');
      }
    };

    fetchRecordDetails();
  }, [record_id]);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!recordData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" gutterBottom>
          Vet Clinic Record Details
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: 4 }} />

      <Grid container spacing={3}>
        {/* Record Date */}
        {recordData.record_date && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Record Date</Typography>
                <Typography>
                  {new Date(recordData.record_date).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        {/* Horse Details and Owner Details بجانب بعض */}
        <Grid container item spacing={3}>
          {/* Horse Details */}
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Horse Details</Typography>
                <Typography>Name: {recordData.horse_name || 'N/A'}</Typography>
                <Typography>
                  RFID Chip Number: {recordData.rfid_chip_number || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Owner Details */}
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Owner Details</Typography>
                <Typography>Name: {recordData.owner_name || 'N/A'}</Typography>
                <Typography>
                  Phone: {recordData.owner_phone || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Procedures */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Procedures</Typography>
              {recordData.procedures && recordData.procedures.length > 0 ? (
                <ul>
                  {recordData.procedures.map((procedure, index) => (
                    <li key={index}>
                      {procedure.procedure_name} - {procedure.price} SAR
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography>No procedures selected</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Medications */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Medications</Typography>
              {recordData.medications && recordData.medications.length > 0 ? (
                <ul>
                  {recordData.medications.map((med, index) => (
                    <li key={index}>
                      {med.medication_name} - {med.dosage} {med.unit} -{' '}
                      {med.quantity} unit(s) - {med.price} SAR
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography>No medications provided</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Total Amount */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Amount</Typography>
              <Typography>{recordData.total_amount} SAR</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Payment Type */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Payment Type</Typography>
              <Typography>{recordData.payment_type_name || 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Notes */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Notes</Typography>
              <Typography>{recordData.notes || 'No notes provided'}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Uploaded Files */}
        {recordData.file_uploads && recordData.file_uploads.length > 0 && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Uploaded Files</Typography>
                <ul>
                  {recordData.file_uploads.map((file, index) => (
                    <li key={index}>
                      <a href={file} target="_blank" rel="noopener noreferrer">
                        {file}
                      </a>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RecordDetails;
