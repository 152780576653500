import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Box, // استيراد Box من MUI
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const apiUrl = process.env.REACT_APP_API_URL;

const HorsesClientsList = () => {
  const [horses, setHorses] = useState([]);
  const [clients, setClients] = useState([]);
  const [colors, setColors] = useState([]);
  const [breeds, setBreeds] = useState([]);
  const [open, setOpen] = useState(false);
  const [horseData, setHorseData] = useState({
    horse_name: '',
    breed: '',
    color_id: '',
    gender: '',
    rfid_chip_number: '',
    registration_number: '',
    registration_date: '',
    notes: '',
    client_id: '',
  });

  useEffect(() => {
    fetchHorses();
    fetchClients();
    fetchColors();
    fetchBreeds();
  }, []);

  const fetchHorses = async () => {
    try {
      const response = await axios.get(`${apiUrl}/vetclinic-horses`);
      setHorses(response.data);
    } catch (error) {
      console.error('Error fetching horses:', error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${apiUrl}/vetclinic-clients`);
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const fetchColors = async () => {
    try {
      const response = await axios.get(`${apiUrl}/horse_colors`);
      setColors(response.data);
    } catch (error) {
      console.error('Error fetching colors:', error);
    }
  };

  const fetchBreeds = async () => {
    try {
      const response = await axios.get(`${apiUrl}/horse_breeds`);
      setBreeds(response.data);
    } catch (error) {
      console.error('Error fetching breeds:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHorseData({ ...horseData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const formattedDate = horseData.registration_date
        ? new Date(horseData.registration_date).toLocaleDateString('en-CA') // تحويل التاريخ إلى صيغة YYYY-MM-DD
        : null;

      const horseDataToSend = {
        ...horseData,
        horse_origin: 'Outside',
        registration_date: formattedDate,
      };

      console.log('Sending data:', horseDataToSend);

      if (horseData.horse_id) {
        await axios.put(
          `${apiUrl}/vetclinic-horses/${horseData.horse_id}`,
          horseDataToSend
        );
      } else {
        await axios.post(`${apiUrl}/vetclinic-horses`, horseDataToSend);
      }

      fetchHorses();
      handleClose();
    } catch (error) {
      console.error('Error saving horse:', error);
    }
  };

  const handleEdit = (horse) => {
    setHorseData(horse);
    handleOpen();
  };

  const handleDelete = async (horse_id) => {
    try {
      await axios.delete(`${apiUrl}/vetclinic-horses/${horse_id}`);
      fetchHorses();
    } catch (error) {
      console.error('Error deleting horse:', error);
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="center" mt={3} mb={2}>
        <h1>Horses and Clients Management</h1>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add Horse
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell> {/* عمود الترقيم */}
            <TableCell>Horse Name</TableCell>
            <TableCell>Origin</TableCell>
            <TableCell>Breed</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>RFID Chip Number</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {horses.map((horse, index) => (
            <TableRow key={horse.horse_id}>
              <TableCell>{index + 1}</TableCell> {/* الترقيم */}
              <TableCell>{horse.horse_name}</TableCell>
              <TableCell>{horse.horse_origin}</TableCell>
              <TableCell>{horse.breed}</TableCell>
              <TableCell>{horse.gender}</TableCell>
              <TableCell>{horse.rfid_chip_number}</TableCell>
              <TableCell>
                {colors.find((color) => color.color_id === horse.color_id)
                  ?.color_english || 'N/A'}
              </TableCell>
              <TableCell>
                {clients.find((client) => client.client_id === horse.client_id)
                  ?.client_name || 'N/A'}
              </TableCell>
              <TableCell>
                <IconButton color="primary" onClick={() => handleEdit(horse)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={() => handleDelete(horse.horse_id)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {horseData.horse_id ? 'Edit Horse' : 'Add Horse'}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="horse_name"
            label="Horse Name"
            type="text"
            fullWidth
            value={horseData.horse_name}
            onChange={handleChange}
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Breed</InputLabel>
            <Select
              name="breed"
              value={horseData.breed}
              onChange={handleChange}
              required
            >
              {breeds.map((breed) => (
                <MenuItem key={breed.breed_id} value={breed.breed_english}>
                  {breed.breed_english} / {breed.breed_arabic}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Color</InputLabel>
            <Select
              name="color_id"
              value={horseData.color_id || ''}
              onChange={handleChange}
            >
              {colors.map((color) => (
                <MenuItem key={color.color_id} value={color.color_id}>
                  {color.color_english} / {color.color_arabic}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Gender</InputLabel>
            <Select
              name="gender"
              value={horseData.gender}
              onChange={handleChange}
              required
            >
              <MenuItem value="S">Stallion / حصان</MenuItem>
              <MenuItem value="M">Mare / فرس</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="rfid_chip_number"
            label="RFID Chip Number"
            type="text"
            fullWidth
            value={horseData.rfid_chip_number}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="registration_number"
            label="Registration Number"
            type="text"
            fullWidth
            value={horseData.registration_number}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="registration_date"
            label="Registration Date"
            type="date"
            fullWidth
            value={
              horseData.registration_date
                ? horseData.registration_date.split('T')[0]
                : ''
            }
            onChange={handleChange}
          />

          <TextField
            margin="dense"
            name="notes"
            label="Notes"
            type="text"
            fullWidth
            value={horseData.notes}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Client</InputLabel>
            <Select
              name="client_id"
              value={horseData.client_id}
              onChange={handleChange}
            >
              {clients.map((client) => (
                <MenuItem key={client.client_id} value={client.client_id}>
                  {client.client_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HorsesClientsList;
