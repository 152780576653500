import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const MedicationsList = () => {
  const { t } = useTranslation();
  const [medications, setMedications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMedications();
  }, []);

  const fetchMedications = () => {
    axios
      .get(`${apiUrl}/medications`)
      .then((response) => setMedications(response.data))
      .catch((error) => console.error('Error fetching medications:', error));
  };

  const handleDelete = (id) => {
    if (window.confirm(t('medicationsList.confirmDelete'))) {
      axios
        .delete(`${apiUrl}/medications/${id}`)
        .then(() => fetchMedications())
        .catch((error) => console.error('Error deleting medication:', error));
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          {t('medicationsList.title')}
        </Typography>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            mb: 1,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/medication-add')}
            style={{ marginTop: 0, alignSelf: 'center' }}
          >
            {t('medicationsList.addNew')}
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ marginTop: 2.5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">
                  {t('medicationsList.name')}
                </TableCell>
                <TableCell align="center">
                  {t('medicationsList.defaultDosage')}
                </TableCell>
                <TableCell align="center">
                  {t('medicationsList.defaultUnit')}
                </TableCell>
                <TableCell align="center">
                  {t('medicationsList.actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medications.length > 0 ? (
                medications.map((medication, index) => (
                  <TableRow key={medication.medication_id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {medication.medication_name}
                    </TableCell>
                    <TableCell align="center">
                      {medication.default_dosage}
                    </TableCell>
                    <TableCell align="center">
                      {medication.default_unit}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          navigate(
                            `/medication-edit/${medication.medication_id}`
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(medication.medication_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    {t('medicationsList.noMedications')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default MedicationsList;
