import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Home from './pages/shared/Home';
import Profile from './pages/Profile';
import Login from './pages/shared/Login';
import UserRegistration from './pages/admin/UserRegistration';
import UserProfile from './pages/Profile';
import UserList from './pages/admin/UserList';
import UserEdit from './pages/admin/UserEdit';
import UserDetails from './pages/admin/UserDetails';
import HorseRegistration from './pages/admin/HorseRegistration';
import Dashboard from './pages/admin/Dashboard';
import HorseList from './pages/admin/HorseList';
import HorseEdit from './pages/admin/HorseEdit';
import Statistics from './pages/admin/Statistics';
import HorseDetails from './pages/shared/HorseDetails';
import PedigreeTree from './pages/shared/PedigreeTree';
import OwnerList from './pages/admin/OwnerList';
import OwnerEdit from './pages/admin/OwnerEdit';
import SystemSettings from './pages/admin/SystemSettings';
import OwnerRegistration from './pages/admin/OwnerRegistration';
import OwnerDetails from './pages/admin/OwnerDetails';
import StableList from './pages/admin/StableList';
import StableDetails from './pages/admin/StableDetails';
import StableRoomList from './pages/admin/StableRoomList';
import StableRoomDetails from './pages/admin/StableRoomDetails';
import AddStable from './pages/admin/AddStable';
import AccommodationList from './pages/admin/AccommodationList';
import { Container } from '@mui/material';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import HorseColorList from './pages/admin/HorseColorList';
import HorseBreedList from './pages/admin/HorseBreedList';
import RoleList from './pages/admin/RoleList';
import ProceduresList from './pages/clinic/ProceduresList';
import RecordList from './pages/clinic/RecordList';
import MedicationsList from './pages/clinic/MedicationsList';
import ProcedureAdd from './pages/clinic/ProcedureAdd';
import MedicationAdd from './pages/clinic/MedicationAdd';
import MedicationsEdit from './pages/clinic/MedicationsEdit';
import VetClinicRecordInside from './pages/clinic/VetClinicRecordInside';
import VetClinicRecordOutside from './pages/clinic/VetClinicRecordOutside';
import RecordDetails from './pages/clinic/RecordDetails';
import VetClinicForm from './pages/clinic/VetClinicForm';
import ProcedureEdit from './pages/clinic/ProcedureEdit';
import ClientsList from './pages/clients/ClientsList';
import HorsesClientsList from './pages/clients/HorsesClientsList';
import ChangePassword from './pages/shared/ChangePassword';
import NotFoundPage from './pages/shared/NotFoundPage';

import axios from 'axios'; // إضافة axios لجلب اسم النظام

function App() {
  const { i18n } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('token')
  );
  const [systemName, setSystemName] = useState('Smart Stable'); // القيمة الافتراضية لاسم النظام

  const theme = createTheme({
    direction: i18n.dir(),
  });

  useEffect(() => {
    document.body.dir = i18n.dir();

    // جلب اسم النظام من قاعدة البيانات
    const fetchSystemName = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/system-name`
        );
        setSystemName(response.data.system_name);
        document.title = response.data.system_name; // تحديث عنوان الصفحة في المتصفح
      } catch (error) {
        console.error('Failed to fetch system name: ' + error.message);
      }
    };

    fetchSystemName();
  }, [i18n]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          systemName={systemName} // تمرير اسم النظام
        />
      </Router>
    </ThemeProvider>
  );
}

function AppContent({ isAuthenticated, setIsAuthenticated }) {
  const location = useLocation();

  return (
    <>
      {/* عرض Navbar في جميع الصفحات ماعدا صفحات تسجيل الدخول */}
      {location.pathname !== '/login' && location.pathname !== '/' && (
        <Navbar
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />
      )}
      <Container>
        <Routes>
          {/* مسارات التطبيق */}
          <Route
            path="/login"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route path="*" element={<NotFoundPage />} />

          <Route
            path="/"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/home" element={<Home />} />
            <Route path="/SystemSettings" element={<SystemSettings />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/register-horse" element={<HorseRegistration />} />
            <Route path="/horse-list" element={<HorseList />} />
            <Route path="/horse-edit/:horseId" element={<HorseEdit />} />
            <Route path="/horse-details/:horseId" element={<HorseDetails />} />
            <Route path="/horse-colors" element={<HorseColorList />} />
            <Route path="/horse-breeds" element={<HorseBreedList />} />
            <Route path="/Statistics" element={<Statistics />} />

            <Route path="/PedigreeTree" element={<PedigreeTree />} />

            {/* إدارة الملاك */}
            <Route path="/owner-list" element={<OwnerList />} />
            <Route path="/owner-edit/:ownerId" element={<OwnerEdit />} />
            <Route path="/register-owner" element={<OwnerRegistration />} />
            <Route path="/owner-details/:ownerId" element={<OwnerDetails />} />
            {/* إدارة الاسطبلات والغرف */}
            <Route path="/stable-list" element={<StableList />} />
            <Route
              path="/stable-details/:stableId"
              element={<StableDetails />}
            />
            <Route
              path="/stable-rooms/:stableId"
              element={<StableRoomList />}
            />
            <Route
              path="/room-details/:roomId"
              element={<StableRoomDetails />}
            />
            {/* إدارة الإقامات */}
            <Route path="/accommodations" element={<AccommodationList />} />
            <Route path="/stable-room-list" element={<StableRoomList />} />
            <Route path="/stables/add" element={<AddStable />} />
            {/* إدارة المستخدمين */}
            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/userregistration" element={<UserRegistration />} />
            <Route path="/user-details/:userId" element={<UserDetails />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/useredit/:userId" element={<UserEdit />} />
            <Route path="/user-details/:userId" element={<UserDetails />} />
            <Route path="/RoleList" element={<RoleList />} />
            {/* إدارة العملاء */}
            <Route path="/ClientsList" element={<ClientsList />} />
            <Route path="/HorsesClientsList" element={<HorsesClientsList />} />
            {/* إدارة العيادة */}
            <Route path="/VetClinicForm" element={<VetClinicForm />} />
            <Route path="/procedures" element={<ProceduresList />} />
            <Route path="/procedure-add" element={<ProcedureAdd />} />
            <Route
              path="/ProcedureEdit/:procedureId"
              element={<ProcedureEdit />}
            />
            <Route path="/medications" element={<MedicationsList />} />
            <Route path="/medication-add" element={<MedicationAdd />} />
            <Route
              path="/medication-edit/:medicationId"
              element={<MedicationsEdit />}
            />

            <Route
              path="/VetClinicRecordInside"
              element={<VetClinicRecordInside />}
            />
            <Route
              path="/VetClinicRecordOutside"
              element={<VetClinicRecordOutside />}
            />
          </Route>
          <Route path="/RecordList" element={<RecordList />} />
          <Route
            path="/record-details/:record_id"
            element={<RecordDetails />}
          />
        </Routes>
      </Container>
      {/* عرض Footer في جميع الصفحات ماعدا صفحات تسجيل الدخول */}
      {location.pathname !== '/login' && location.pathname !== '/' && (
        <Footer />
      )}
    </>
  );
}

export default App;
