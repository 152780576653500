import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_URL;

const ProcedureEdit = () => {
  const { t } = useTranslation(); // Translation support
  const { procedureId } = useParams();
  const navigate = useNavigate();
  const [procedure, setProcedure] = useState({
    procedure_name: '',
    description: '',
    price: '',
  });
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch procedure data when the page loads
    axios
      .get(`${apiUrl}/procedures/${procedureId}`)
      .then((response) => setProcedure(response.data))
      .catch((error) => {
        console.error('Error fetching procedure data:', error);
        setError(t('fetch_procedure_error'));
      });
  }, [procedureId, t]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`${apiUrl}/procedures/${procedureId}`, procedure)
      .then(() => {
        setSuccess(t('procedure_update_success'));
        setError('');
        navigate('/procedures'); // Redirect after successful update
      })
      .catch((error) => {
        console.error('Error updating procedure:', error);
        setError(t('procedure_update_error'));
        setSuccess('');
      });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5, textAlign: 'center' }}>
          {t('edit_procedure')}
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}

        <Box
          component="form"
          sx={{ width: '100%', maxWidth: 600, mt: 2 }}
          onSubmit={handleSubmit}
        >
          <TextField
            label={t('procedure_name')}
            value={procedure.procedure_name}
            onChange={(e) =>
              setProcedure({ ...procedure, procedure_name: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('description')}
            value={procedure.description}
            onChange={(e) =>
              setProcedure({ ...procedure, description: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('price')}
            type="number"
            value={procedure.price}
            onChange={(e) =>
              setProcedure({ ...procedure, price: e.target.value })
            }
            fullWidth
            margin="normal"
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginRight: 2 }}
            >
              {t('save')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/procedures')}
            >
              {t('cancel')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ProcedureEdit;
