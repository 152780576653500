import React, { useState } from 'react';
import Tree from 'react-d3-tree';

// تحويل بيانات النسب إلى هيكل الشجرة
const convertPedigreeDataToTree = (data) => {
  const buildTree = (parent, prefix) => {
    if (!parent) return null;
    const fatherKey = `${prefix}M`;
    const motherKey = `${prefix}F`;

    return {
      name: parent.name,
      attributes: {
        color: parent.color || 'Unknown',
        dob: parent.dob || 'Unknown',
        breed: parent.breed || 'Unknown',
        registry_number: parent.registry_number || 'Unknown',
        sex: parent.sex || null,
      },
      children: [
        data[fatherKey] ? buildTree(data[fatherKey], fatherKey) : null,
        data[motherKey] ? buildTree(data[motherKey], motherKey) : null,
      ].filter(Boolean), // حذف العناصر غير الموجودة
    };
  };

  return buildTree(data.main_horse, '');
};

// إعداد مخصص لـ node
const renderCustomNode = ({ nodeDatum, toggleNode }) => {
  const isMale =
    nodeDatum.attributes && nodeDatum.attributes.sex === 'Stallion';
  const isFemale = nodeDatum.attributes && nodeDatum.attributes.sex === 'Mare';

  return (
    <g>
      {/* رسم مستطيل node */}
      <rect
        width="200"
        height="100"
        x="-100"
        y="-50"
        fill={isMale ? 'lightblue' : isFemale ? 'pink' : 'lightgray'}
        stroke="black"
        strokeWidth="1"
        rx="10"
        ry="10"
      />
      {/* اسم الحصان */}
      <text fill="black" x="0" y="-20" textAnchor="middle" fontSize="10">
        {nodeDatum.name}
      </text>
      {/* معلومات أخرى */}
      <text fill="black" x="0" y="0" textAnchor="middle" fontSize="10">
        {`Color: ${nodeDatum.attributes.color}`}
      </text>
      <text fill="black" x="0" y="15" textAnchor="middle" fontSize="10">
        {`DOB: ${nodeDatum.attributes.dob}`}
      </text>
      <text fill="black" x="0" y="30" textAnchor="middle" fontSize="10">
        {`Breed: ${nodeDatum.attributes.breed}`}
      </text>
    </g>
  );
};

// مكون React لعرض شجرة النسب
const PedigreeTree = () => {
  const [treeData, setTreeData] = useState(null);

  const handleLoadPedigree = () => {
    // بيانات النسب التجريبية
    const pedigreeData = {
      M: {
        name: 'FA EL RASHEEM',
        color: 'Bay',
        dob: '2011',
        breed: 'Arabian',
        sex: 'Stallion',
        registry_number: null,
      },
      F: {
        name: 'CR JAMEELAH',
        color: 'Gray',
        dob: '2013',
        breed: 'Arabian',
        sex: 'Mare',
        registry_number: null,
      },
      MM: {
        name: 'FA EL SHAWAN',
        color: 'Bay',
        dob: '2005',
        breed: 'Arabian',
        registry_number: 'AHR 621258',
      },
      MF: {
        name: 'VIRTUOSA MLR',
        color: 'Bay',
        dob: '2001',
        breed: 'Arabian',
        registry_number: 'AHR 586036',
      },
      FM: {
        name: 'WH JUSTICE',
        color: 'Gray',
        dob: '1999',
        breed: 'Arabian',
        registry_number: 'AHR 569947',
      },
      FF: {
        name: 'FFORGET ME NOT',
        color: 'Gray',
        dob: '1999',
        breed: 'Arabian',
        registry_number: null,
      },
      main_horse: {
        name: 'ADEL',
        sex: 'Stallion',
        breed: 'Arabian',
        date_of_birth: '2019-07-29',
      },
    };

    // تحويل البيانات إلى هيكل الشجرة
    const treeStructure = convertPedigreeDataToTree(pedigreeData);
    setTreeData(treeStructure);
  };

  return (
    <div>
      <h1>شجرة نسب الحصان ADEL</h1>
      <button onClick={handleLoadPedigree}>تحميل بيانات النسب</button>
      <div style={{ width: '100%', height: '1200px', marginTop: '20px' }}>
        {treeData ? (
          <Tree
            data={treeData}
            renderCustomNodeElement={renderCustomNode} // استخدام العنصر المخصص
            orientation="vertical"
            pathFunc="straight" // جعل الروابط مستقيمة
            separation={{ siblings: 2, nonSiblings: 3 }} // زيادة المسافات بين العقد
          />
        ) : (
          <p>قم بتحميل بيانات النسب لعرض الشجرة</p>
        )}
      </div>
    </div>
  );
};

export default PedigreeTree;
