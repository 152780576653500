import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Box, Typography } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const ProcedureAdd = () => {
  const navigate = useNavigate();
  const [procedure, setProcedure] = useState({
    procedure_name: '',
    description: '',
    price: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${apiUrl}/procedures`, procedure)
      .then(() => navigate('/procedures'))
      .catch((error) => console.error('Error adding procedure:', error));
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5, textAlign: 'center' }}>
          Add Procedure
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ width: '100%', maxWidth: 600, mt: 2 }}
        >
          <TextField
            label="Procedure Name"
            value={procedure.procedure_name}
            onChange={(e) =>
              setProcedure({ ...procedure, procedure_name: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={procedure.description}
            onChange={(e) =>
              setProcedure({ ...procedure, description: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Price"
            type="number"
            value={procedure.price}
            onChange={(e) =>
              setProcedure({ ...procedure, price: e.target.value })
            }
            fullWidth
            margin="normal"
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginRight: 2 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/procedures')}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ProcedureAdd;
