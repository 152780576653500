import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

const HorseRegistration = () => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('');
  const [nameArabic, setNameArabic] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [fatherNameArabic, setFatherNameArabic] = useState('');
  const [motherName, setMotherName] = useState('');
  const [motherNameArabic, setMotherNameArabic] = useState('');
  const [breederName, setBreederName] = useState('');
  const [breederNameArabic, setBreederNameArabic] = useState('');
  const [breedId, setBreedId] = useState('');
  const [rfidChipNumber, setRfidChipNumber] = useState('');
  const [colorId, setColorId] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [countryOfOriginId, setCountryOfOriginId] = useState('');
  const [owners, setOwners] = useState([]);
  const [colors, setColors] = useState([]);
  const [breeds, setBreeds] = useState([]);
  const [countries, setCountries] = useState([]);
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [registrationDate, setRegistrationDate] = useState('');
  const [pedigreeHtml, setPedigreeHtml] = useState('');
  const [image, setImage] = useState(null);
  const [registrationCertificate, setRegistrationCertificate] = useState(null); // إضافة حالة لشهادة التسجيل
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ownersResponse = await axios.get(`${apiUrl}/owners/summary`);
        setOwners(ownersResponse.data);

        const colorsResponse = await axios.get(`${apiUrl}/horse_colors`);
        setColors(colorsResponse.data);

        const breedsResponse = await axios.get(`${apiUrl}/horse_breeds`);
        setBreeds(breedsResponse.data);

        const countriesResponse = await axios.get(`${apiUrl}/countries`);
        setCountries(countriesResponse.data);
      } catch (err) {
        console.error('Failed to fetch data: ' + err.message);
      }
    };

    fetchData();
  }, []);

  const validateFields = () => {
    if (!name) {
      setError(t('please_fill_horse_name'));
      return false;
    }
    if (!nameArabic) {
      setError(t('please_fill_horse_name_arabic'));
      return false;
    }
    if (!gender) {
      setError(t('please_select_gender'));
      return false;
    }
    if (!dateOfBirth) {
      setError(t('please_select_date_of_birth'));
      return false;
    }
    if (!ownerId) {
      setError(t('please_select_owner'));
      return false;
    }
    if (!registrationNumber) {
      setError(t('please_fill_registration_number'));
      return false;
    }
    if (!registrationDate) {
      setError(t('please_select_registration_date'));
      return false;
    }
    if (!countryOfOriginId) {
      setError(t('please_select_country_of_origin'));
    }
    if (!colorId) {
      setError(t('please_select_color'));
      return false;
    }
    if (!breedId) {
      setError(t('please_select_breed'));
      return false;
    }
    if (!image) {
      setError(t('please_upload_image'));
      return false;
    }
    if (!registrationCertificate) {
      setError(t('please_upload_registration_certificate'));
      return false;
    }

    // If all fields are filled
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('name_arabic', nameArabic);
    formData.append('father_name', fatherName);
    formData.append('father_name_arabic', fatherNameArabic);
    formData.append('mother_name', motherName);
    formData.append('mother_name_arabic', motherNameArabic);
    formData.append('breeder_name', breederName);
    formData.append('breeder_name_arabic', breederNameArabic);
    formData.append('breed_id', breedId);
    formData.append('rfid_chip_number', rfidChipNumber);
    formData.append('color_id', colorId);
    formData.append('gender', gender);
    formData.append('date_of_birth', dateOfBirth);
    formData.append('owner_id', ownerId);
    formData.append('country_of_origin_id', countryOfOriginId);
    formData.append('registration_number', registrationNumber);
    formData.append('registration_date', registrationDate);
    formData.append('pedigree_html', pedigreeHtml);
    if (image) {
      formData.append('image', image); // إضافة الصورة إلى البيانات المرسلة
    }
    if (registrationCertificate) {
      formData.append('registration_certificate', registrationCertificate); // إضافة شهادة التسجيل إلى البيانات المرسلة
    }

    try {
      await axios.post(`${apiUrl}/horses`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccess(t('horse_registered_successfully'));
      setError('');
      navigate('/horse-list');
    } catch (err) {
      setError(t('error_registering_horse'));
      setSuccess('');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
      >
        <Typography component="h1" variant="h5">
          {t('register_horse')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label={t('horse_name')}
            name="name"
            autoComplete="name"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="nameArabic"
            label={t('horse_name_arabic')}
            name="nameArabic"
            autoComplete="nameArabic"
            value={nameArabic}
            onChange={(e) => setNameArabic(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="fatherName"
            label={t('father_name')}
            name="fatherName"
            value={fatherName}
            onChange={(e) => setFatherName(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="fatherNameArabic"
            label={t('father_name_arabic')}
            name="fatherNameArabic"
            value={fatherNameArabic}
            onChange={(e) => setFatherNameArabic(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="motherName"
            label={t('mother_name')}
            name="motherName"
            value={motherName}
            onChange={(e) => setMotherName(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="motherNameArabic"
            label={t('mother_name_arabic')}
            name="motherNameArabic"
            value={motherNameArabic}
            onChange={(e) => setMotherNameArabic(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="breederName"
            label={t('breeder_name')}
            name="breederName"
            value={breederName}
            onChange={(e) => setBreederName(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="breederNameArabic"
            label={t('breeder_name_arabic')}
            name="breederNameArabic"
            value={breederNameArabic}
            onChange={(e) => setBreederNameArabic(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="breedId-label">{t('breed')}</InputLabel>
            <Select
              labelId="breedId-label"
              id="breedId"
              value={breedId}
              label={t('breed')}
              onChange={(e) => setBreedId(e.target.value)}
            >
              {breeds.map((breed) => (
                <MenuItem key={breed.breed_id} value={breed.breed_id}>
                  {breed.breed_english} / {breed.breed_arabic}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            fullWidth
            id="rfidChipNumber"
            label={t('rfid_chip_number')}
            name="rfidChipNumber"
            autoComplete="rfidChipNumber"
            value={rfidChipNumber}
            onChange={(e) => setRfidChipNumber(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="colorId-label">{t('color_id')}</InputLabel>
            <Select
              labelId="colorId-label"
              id="colorId"
              value={colorId}
              label={t('color_id')}
              onChange={(e) => setColorId(e.target.value)}
            >
              {colors.map((color) => (
                <MenuItem key={color.color_id} value={color.color_id}>
                  {color.color_english} / {color.color_arabic}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="gender-label">{t('gender')}</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              value={gender}
              label={t('gender')}
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value="S">{t('STALLION')} / حصان</MenuItem>
              <MenuItem value="M">{t('MARE')} / فرس</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            required
            fullWidth
            id="dateOfBirth"
            label={t('date_of_birth')}
            name="dateOfBirth"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="ownerId-label">{t('owner_id')}</InputLabel>
            <Select
              labelId="ownerId-label"
              id="ownerId"
              value={ownerId}
              label={t('owner_id')}
              onChange={(e) => {
                setOwnerId(e.target.value);
              }}
            >
              {owners.map((owner) => (
                <MenuItem key={owner.owner_id} value={owner.owner_id}>
                  {owner.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="countryOfOriginId-label">
              {t('country_of_origin')}
            </InputLabel>
            <Select
              labelId="countryOfOriginId-label"
              id="countryOfOriginId"
              value={countryOfOriginId}
              label={t('country_of_origin')}
              onChange={(e) => setCountryOfOriginId(e.target.value)}
            >
              {/* إضافة السعودية كأول خيار دائم */}
              <MenuItem value={149}>
                Saudi Arabia / المملكة العربية السعودية
              </MenuItem>

              {/* باقي الدول مع إزالة السعودية من القائمة إذا كانت موجودة */}
              {countries
                .filter((country) => country.country_id !== 149) // إزالة السعودية من القائمة الأصلية
                .map((country) => (
                  <MenuItem key={country.country_id} value={country.country_id}>
                    {country.country_english} / {country.country_arabic}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            margin="normal"
            required
            fullWidth
            id="registrationNumber"
            label={t('registration_number')}
            name="registrationNumber"
            autoComplete="registrationNumber"
            value={registrationNumber}
            onChange={(e) => setRegistrationNumber(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="registrationDate"
            label={t('registration_date')}
            name="registrationDate"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={registrationDate}
            onChange={(e) => setRegistrationDate(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="pedigreeHtml"
            label={t('pedigree_html')}
            name="pedigreeHtml"
            multiline
            rows={4}
            value={pedigreeHtml}
            onChange={(e) => setPedigreeHtml(e.target.value)}
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            style={{ marginTop: '16px' }}
          />
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {t('upload_horse_image')} *
          </Typography>
          <input
            type="file"
            accept="application/pdf"
            onChange={(e) => setRegistrationCertificate(e.target.files[0])}
            style={{ marginTop: '16px' }}
          />
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {t('upload_registration_certificate')} *
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between', // توزيع الأزرار بالتساوي
              gap: 2, // إضافة مسافة بين الأزرار
              mt: 3, // إضافة مسافة في الأعلى
              mb: 2, // إضافة مسافة في الأسفل
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ flex: 1 }} // جعل الزر يمتد ليأخذ نفس حجم الزر الآخر
            >
              {t('register_horse')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/horse-list')}
              sx={{ flex: 1 }} // جعل الزر يمتد ليأخذ نفس حجم الزر الآخر
            >
              {t('Cancel')}
            </Button>
          </Box>

          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>
      </Box>
    </Container>
  );
};

export default HorseRegistration;
