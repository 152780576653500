import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Alert,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Logo from "../../assets/images/Logo-NUDRAH.png";
import SmartStableLogo from "../../assets/images/SmartStable.png"; // استيراد شعار البرنامج
const apiUrl = process.env.REACT_APP_API_URL;

const Login = ({ setIsAuthenticated }) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/login`, {
        username,
        password,
      });

      // تحقق من أن الاستجابة تحتوي على owner_id
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userName", response.data.userName);
      localStorage.setItem("userId", response.data.userId);
      localStorage.setItem("owner_id", response.data.owner_id); // تخزين owner_id

      setIsAuthenticated(true);
      navigate("/home");
    } catch (err) {
      setError(t("invalid_credentials"));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "150px", marginBottom: "20px" }}
        />
        <Typography component="h1" variant="h5">
          {/* {t("login")} */}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label={t("username")}
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("password")}
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("login")}
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
        <Box sx={{ mt: 5, textAlign: "center" }}>
          <img
            src={SmartStableLogo}
            alt="Smart Stable Logo"
            style={{ width: "60px", marginBottom: "10px" }} // إضافة شعار البرنامج
          />
          <Typography variant="body2" color="text.secondary">
            Power By Smart Stable
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
